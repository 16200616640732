import React from "react";
import DynamicTable from "./dynamicTable";
import { useTranslation } from "react-i18next";

const Modal = ({ isOpen, onClose, data, text, btntext }) => {
    const { t } = useTranslation();

    if (!isOpen) return null;

    return (
        <>
            <div
                className={`modal show`}
                id="orderDetailModal"
                tabIndex="-1"
                aria-hidden={!isOpen}
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-dialog-scrollable custom-modal">
                    <div className="modal-content custom-modal">
                        <div className="modal-header">
                            <h5 className="modal-title custom-modal-title">
                                {text}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={onClose}
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <DynamicTable data={data}/>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={onClose}
                            >
                                {t("properties.close")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal-backdrop fade show"
                onClick={onClose}
            ></div>
        </>
    );
};

export default Modal;
