import { useTranslation } from 'react-i18next';
import InputEmail from '../../components/common/Input/inputEmail';
import InputText from '../../components/common/Input/inputText';
import FilterBodyComponent from "../../components/rGlobalComponents/FilterBodyComponent";
import { useFilter } from '../../hook/useFilter';
import { useHandleInputChange } from '../../hook/useHandleInputChange';
import '../../style/filter.css';
import { initialState } from './initialStates/create';

const Filter = ({handleFilterData}) => {
    const {t} = useTranslation();
    const {formState, handleInputChange} = useHandleInputChange(initialState);
    const {getQueryString} = useFilter(formState);

    const handleFilter = async (e) => {
        e.preventDefault();
        const queryString = await getQueryString();
        handleFilterData(queryString)
    };

    const handleRestFilter = async (e) => {
        e.preventDefault();
        handleFilterData(null)
    };

    return (
        <FilterBodyComponent handleFilter={handleFilter} handleRestFilter={handleRestFilter}>
            <InputText
                className='col-md-2'
                name="name"
                label={t('properties.name')}
                value={formState?.name}
                onChange={handleInputChange}
            />

            <InputText
                className='col-md-2'
                name="surname"
                label={t('properties.surname')}
                value={formState?.surname}
                onChange={handleInputChange}
            />

            <InputEmail
                className='col-md-2'
                name="email"
                label={t('properties.email')}
                value={formState?.email}
                onChange={handleInputChange}
            />
        </FilterBodyComponent>
    );
};

export default Filter;