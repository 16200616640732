
import { useTranslation } from 'react-i18next';
const TableHeaderProperties = () => {

    const { t } = useTranslation();
    return (
        <tr>
            <th>#</th>
            <th>{t('properties.name')}</th>
            <th>{t('properties.surname')}</th>
            <th>{t('properties.email')}</th>
            <th>{t('role.role')}</th>
            <th>{t('properties.status')}</th>
            <th colspan="2">{t('properties.actions')}</th>
        </tr>
    )
}

export default TableHeaderProperties