import React from "react";
import { useTranslation } from 'react-i18next';
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import ActionButtons from "../../../components/common/Button/ActionButtons/actionButtons";
import OpenDetail from "../../../components/common/OpenDetail/openDetail";
import { useServicePoints } from "../../../features/servicePoint/useServicePoints";
import TableHeaderProperties from "./tableHeaderProperties";

const Table = ({ data, lang }) => {
    const { t } = useTranslation();
    const { updateStatus, deleteServicePoint, getObject } = useServicePoints()
    return (
        <div className="table-responsive">
            <table id="example2" className="table table-striped table-bordered">
                <thead>
                    <TableHeaderProperties />
                </thead>
                <tbody>
                    {data?.map((obj) => (
                        <tr key={obj.id}>
                            <td>{obj.id}</td>

                            <td>
                                {obj.image ? (
                                    <div className="custom-table-img">
                                        <img
                                            src={`${obj.image}`}
                                            alt="Service Point"
                                            className="table-image"
                                            style={{
                                                width: '100px',
                                                height: '100px'
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {t('properties.noImage')}
                                    </>
                                )}
                            </td>

                            <td>{obj?.name?.[lang] || 'N/A'}</td>
                            <td>{obj?.address?.[lang] || 'N/A'}</td>
                            <td>{obj?.area?.name?.[lang] || 'N/A'}</td>
                            <td>{obj?.relevant_person || 'N/A'}</td>
                            <td>{obj?.servicepoint_number || 'N/A'}</td>
                            <td>{obj?.latitude || 'N/A'}</td>
                            <td>{obj?.longitude || 'N/A'}</td>
                            <td>{obj?.phone || 'N/A'}</td>
                            <td>{obj?.opening_time || 'N/A'}</td>
                            <td>{obj?.closing_time || 'N/A'}</td>
                            <td className="toggle-td">
                                <ToggleSwitch initialState={obj.status} onChange={updateStatus} id={obj.id} />
                            </td>
                            <td >
                                <OpenDetail
                                    text={t("properties.servicePointDetails")}
                                    editState={getObject(obj)}
                                />
                            </td>
                            <td>
                                <ActionButtons
                                    editLink={`/service-point/show/${obj.id}`}
                                    editState={obj.id}
                                    deleteFunction={deleteServicePoint}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <TableHeaderProperties />
                </tfoot>
            </table>
        </div>
    );
};

export default Table;
