import { useTranslation } from 'react-i18next';
import InputSelect from '../../components/common/Input/inputSelect';
import InputText from '../../components/common/Input/inputText';
import FilterBodyComponent from "../../components/rGlobalComponents/FilterBodyComponent";
import { useArea } from '../../features/area/useArea';
import { useSerivePointTypes } from '../../features/servicePointType/useServicePointType';
import { useFilter } from '../../hook/useFilter';
import { useHandleInputChange } from '../../hook/useHandleInputChange';
import '../../style/filter.css';
import { initialState } from './initialStates/filter';

const Filter = ({ handleFilterData }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language
    const { formState, handleInputChange } = useHandleInputChange(initialState);
    const { allservicepointTypes } = useSerivePointTypes();
    const { allAreas } = useArea();
    const { getQueryString } = useFilter(formState);

    const handleFilter = async (e) => {
        e.preventDefault();
        const queryString = await getQueryString();
        handleFilterData(queryString)
    };

    const handleRestFilter = async (e) => {
        e.preventDefault();
        handleFilterData(null)
    };

    return (
        <FilterBodyComponent handleFilter={handleFilter} handleRestFilter={handleRestFilter}>

            <InputSelect
                className="col-md-2"
                name="servicepoint_type_id"
                label={t("properties.servicePointType")}
                options={allservicepointTypes}
                value={formState.servicepoint_type_id}
                onChange={handleInputChange}
                lang={lang}
            />

            <InputSelect
                className="col-md-2"
                name="area_id"
                label={t("properties.area_id")}
                options={allAreas}
                value={formState.area_id}
                onChange={handleInputChange}
                lang={lang}
            />

            <InputText
                className='col-md-2'
                name="name"
                label={t('properties.name')}
                value={formState?.name}
                onChange={handleInputChange}
            />
            <InputText
                className='col-md-2'
                name="relevant_person"
                label={t('properties.relevant_person')}
                value={formState?.relevant_person}
                onChange={handleInputChange}
            />
            <InputText
                className='col-md-2'
                name="address"
                label={t('properties.address')}
                value={formState?.address}
                onChange={handleInputChange}
            />

            <InputText
                className='col-md-2'
                name="phone"
                label={t('properties.phone')}
                value={formState?.phone}
                onChange={handleInputChange}
            />

        </FilterBodyComponent>
    );
};

export default Filter;