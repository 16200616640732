import Components from "./Components";

const Login = () => {

    return (
        <>
            <Components />
        </>
    );
};

export default Login;
