import React, { useEffect, useState } from 'react';

const RememberMe = () => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const sessionData = localStorage.getItem('rememberMeSession');

    if (!isChecked) {
      const sessionData = {
        startTime: Date.now(),
        expiresAt: Date.now() + (12 * 60 * 60 * 1000),
      };
      localStorage.setItem('rememberMeSession', JSON.stringify(sessionData));
    } else {
      localStorage.removeItem('rememberMeSession');
    }


  }, [isChecked]);

  const handleRememberMe = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="col-md-6">
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="flexSwitchCheckChecked"
          checked={isChecked}
          onChange={handleRememberMe}
        />
        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
          Remember Me
        </label>
      </div>
    </div>
  );
};

export default RememberMe;
