import { useState } from 'react';
import { useUser } from '../../../features/user/userActions';

export const useOnSavePostClicked = (formState = {}) => {
    const { addUser } = useUser();
    const [response, setResponse] = useState();
    const [loading, setLoading] = useState(false);
    const [formPostError, setFormPostErrorr] = useState(false);

    const onSavePostClicked = async () => {

        setLoading(true);
        try {
            const formData = {
                name: formState.name,
                email: formState.email,
                surname: formState.surname,
                password: formState.password
            };
            const response = await addUser(formData);
            setResponse(response);
            setFormPostErrorr(response.errors);
        } catch (error) {
            const errorResponse = error?.response?.data || { message: 'Bilinmeyen hata oluştu' };
            setResponse(errorResponse);
            return errorResponse;
        } finally {
            setLoading(false);
        }
    };

    return {
        response,
        formPostError,
        loading,
        onSavePostClicked,
    };
};
