export const initialState = {
    area_id: '',
    relevant_person: '',
    servicepoint_type_id: null,
    name: '',
    address: '',
    image: '',
    opening_time: '',
    closing_time: '',
    phone: '',
    latitude: null,
    longitude: null
};
