import React, {useState} from "react";
import {useTranslation} from "react-i18next";


const FilterContainerComponent = ({children}) => {
    const {t} = useTranslation()

    const [isOpen, setIsOpen] = useState(false);
    const toggleFilter = (event) => {
        event.stopPropagation()
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="px-3">
                <div className="px-3 rounded-2 bg-light-white-4">
                    <div onClick={toggleFilter} className="row my-2 py-2 cursor-pointer">
                        <div className="col-md-6 pt-1">
                    <span>
                        {t("filter.filter")}
                    </span>
                        </div>
                        <div className="col-md-6 text-end">
                            <button onClick={toggleFilter} className="btn btn-sm btn-light text-center">
                                {isOpen ? <i className="w-100 lni lni-minus icon-color"></i> :
                                    <i className="w-100 lni lni-plus icon-color"></i>}
                            </button>
                        </div>
                    </div>
                    {isOpen && children}
                </div>
            </div>
        </>
    )

}

export default FilterContainerComponent;