import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';
const fetcher = url => apiClient.get(url).then(res => res.data);

export const useServicePoints = () => {
    const { t, i18n } = useTranslation()
    const [itemsPerPage, setPerPge] = useState(50)
    const lang = i18n.language
    const [page, setPage] = useState(1);
    const { data, error } = useSWR(
        `/servicepoint?perPage=${itemsPerPage}&page=${page}`,
        fetcher,
        { revalidateOnFocus: false }
    );
    const { data: allServicePoints } = useSWR(`/servicepoint`, fetcher, {
        revalidateOnFocus: false,
    });

    const lastPage = data?.data?.servicepoints?.last_page;
    const total = data?.data?.servicepoints?.total;
    const servicePoints = data?.data?.servicepoints?.data;

    const createServicePoint = async (newData) => {
        try {
            const response = await apiClient.post(`/servicepoint`, newData);
            mutate(`/servicepoint?perPage=${itemsPerPage}&page=${page}`);
            mutate(`/servicepoint`);
            return response.data
        } catch (error) {
            return error.response.data
        }
    };

    const updateServicePoint = async (id, updatedData) => {
        try {
            const response = await apiClient.put(`/servicepoint/${id}`, updatedData);
            mutate(`/servicepoint?perPage=${itemsPerPage}&page=${page}`);
            return response
        } catch (err) {
            return err.response.data
        }

    };

    const deleteServicePoint = async (id) => {
        try {
            const response = await apiClient.delete(`/servicepoint/${id}`);
            mutate(`/servicepoint?perPage=${itemsPerPage}&page=${page}`);
            return response.data;
        } catch (err) {
            return err.response.data
        }

    };

    const getServicePointById = async (servicePointId) => {
        const response = await apiClient.get(`/servicepoint/${servicePointId}`);
        return response.data;
    };
    const searchData = async (queryString) => {
        try {
            const url = queryString
                ? `/servicepoint?perPage=${itemsPerPage}&${queryString}`
                : `/servicepoint?perPage=${itemsPerPage}&page=${page}`;
            const response = await apiClient.get(url);

            mutate(
                `/servicepoint?perPage=${itemsPerPage}&page=${page}`,
                {
                    data: {
                        servicepoints: {
                            data: response?.data?.data?.servicepoints?.data || [],
                            current_page: response?.data?.data?.servicepoints?.current_page || 1,
                            last_page: response?.data?.data?.servicepoints?.last_page,
                            total: response?.data?.data?.servicepoints?.total,
                            per_page: itemsPerPage
                        }
                    },
                    message: 'Success',
                    code: 200
                },
                false
            );

            return response?.data?.data?.servicepoints?.data;
        } catch (err) {
            return err.response?.data;
        }
    }

    const updateStatus = async (id) => {
        try {
            const response = await apiClient.put(`/servicepoint/${id}/status`);
            mutate(`/servicepoint?perPage=${itemsPerPage}&page=${page}`);
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    };

    const getObject = (object) => {
        const newObject = {
            name: object?.name?.[lang],
            address: object?.address?.[lang],
            area: object?.area?.name[lang],
            servicepoint_type: object?.servicepoint_type?.name?.[lang],
            phone: object?.phone,
            opening_time: object?.opening_time,
            closing_time: object?.closing_time,
            latitude: object?.latitude,
            longitude: object?.longitude,
            status: object?.status == 0 ? t('properties.inactive') : t('properties.active')
        }
        return newObject;
    }

    const getAllServicePoints = async () => {
        try {
            const response = await apiClient.get(`/servicepoint`);
            mutate(`/servicepoint`);
            return Array.isArray(response?.data?.data?.servicepoints) ? response?.data?.data?.servicepoints : [];
        } catch (err) {
            return err.response.data;
        }
    }
    return {
        allServicePoints: Array.isArray(allServicePoints?.data?.servicepoints) ? allServicePoints?.data?.servicepoints : [],
        itemsPerPage,
        currentPage: page,
        servicePoints,
        isLoading: !error && !data,
        isError: error,
        total,
        lastPage,
        searchData,
        setPage,
        mutate,
        createServicePoint,
        updateServicePoint,
        deleteServicePoint,
        getServicePointById,
        updateStatus,
        getObject,
        setPerPge,
        getAllServicePoints
    };
};
