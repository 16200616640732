import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import ToastNotification from '../../../toastNotfication/index';
import Popup from '../../../Popup/Popup';

const ActionButtons = ({editLink, deleteFunction, editState}) => {
    const {t} = useTranslation();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [response, setResponse] = useState();
    const [selectedObject, setSelectedObject] = useState(null);

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedObject(null);
    };

    const handleConfirm = async () => {
        if (selectedObject) {
            try {
                const response = await deleteFunction(selectedObject);
                setResponse({type: 'success', message: response.message || t('common.success'), code: 200});

            } catch (error) {
                setResponse({type: 'error', message: error.message || t('common.errorOccurred')});
            }
        }
        handleClosePopup();
    };
    return (
        <>
            <div className="col">
                <div
                    style={{justifyContent: "center"}}
                    className="d-flex align-items-center justify-content-center theme-icons shadow-sm p-2 cursor-pointer rounded"
                >
                    <div className="text-white" style={{paddingInline: "7px"}}>
                        <Link to={editLink} state={editState}>
                            <i className="lni lni-pencil-alt"></i>
                        </Link>
                    </div>
                    <div
                        className="text-white"
                        onClick={() => {
                            setSelectedObject(editState);
                            handleOpenPopup();
                        }}
                    >
                        <i className="lni lni-trash"></i>
                    </div>
                </div>
            </div>
            <Popup
                text={t('common.confirmDelete')}
                isOpen={isPopupOpen}
                onClose={handleClosePopup}
                onConfirm={handleConfirm}
            />
            <ToastNotification response={response}/>
        </>
    );
};

export default ActionButtons;
