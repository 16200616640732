import React from 'react';
import ApexCharts from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
const LineChart = () => {
    const { t } = useTranslation();
    const getLastWeekDates = () => {
        const dates = [];
        for (let i = 6; i >= 0; i--) {
            const date = new Date();
            date.setDate(date.getDate() - i);
            dates.push(date.toISOString().split('T')[0]);
        }
        return dates;
    };

    const text = t("home.successfulOrderRevenue")
    const name = t("home.successfulOrderAmount")
    const data = [12, 21, 30, 24, 36, 36, 33];

    const options = {
        series: [{
            name: name,
            data: data
        }],
        chart: {
            foreColor: 'rgba(255, 255, 255, 0.65)',
            height: 400,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: true
            },
            dropShadow: {
                enabled: true,
                top: 3,
                left: 14,
                blur: 4,
                opacity: 0.10,
            }
        },
        stroke: {
            width: 5,
            curve: 'smooth'
        },
        grid: {
            show: true,
            borderColor: 'rgba(255, 255, 255, 0.12)',
            strokeDashArray: 4,
        },
        tooltip: {
            theme: 'dark',
        },
        xaxis: {
            type: 'datetime',
            categories: getLastWeekDates(), // Use the last 7 days
        },
        title: {
            align: 'left',
            style: {
                fontSize: "16px",
                color: '#fff'
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                gradientToColors: ['#fff'],
                shadeIntensity: 1,
                type: 'horizontal',
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100, 100, 100]
            },
        },
        markers: {
            size: 4,
            colors: ["#000"],
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
                size: 7,
            }
        },
        colors: ["#fff"],
        yaxis: {
            title: {
                text: text,
            },
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-0 text-uppercase table-name">{t("home.successfulOrderRevenue")}</h6>
                    <hr />
                    <ApexCharts options={options} series={options.series} type="line" height={350} />
                </div>
            </div>
        </>
    );
};

export default LineChart;
