import {useUser} from "../../../features/user/userActions";
import FilterContainerComponent from "../../../components/rGlobalComponents/FilterContainerComponent";
import {initialState} from "../initialStates/create";
import Filter from "../../user/filter";

const FilterContainer = () => {
    const {searchData} = useUser();

    const handleFilterData = async (queryString) => {
        await searchData(queryString);
    }
    return (
        <FilterContainerComponent>
            <Filter model={initialState} handleFilterData={handleFilterData}/>
        </FilterContainerComponent>
    )
}

export default FilterContainer