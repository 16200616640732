import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './style.css'; // Yuxarıdakı CSS-i burada saxlayın

const InputPhone = ({ name, label, formError, value, onChange, className = "col-md-6" }) => {
    const handleChange = (phone) => {
        onChange({ target: { name, value: phone } });
    };

    return (
        <div className={className}>
            {label && <label htmlFor={name} className="form-label text-white">{label}</label>}
            <PhoneInput
                country={'az'}
                value={value || ''}
                onChange={handleChange}
                name={name}
                className="form-control phone-form"
            />
            {formError?.[name] && (
                <div className="invalid-feedback">
                    {formError[name].join(', ')}
                </div>
            )}
        </div>
    );
};

export default InputPhone;
