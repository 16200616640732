import { useEffect, useState } from "react";

export const useFilter = (initialState = {}) => {
    const [formState, setFormState] = useState(initialState);
    const [filterUrl, setFilterUrl] = useState("");
    useEffect(() => {
        setFormState(initialState)
    }, [initialState])
    const getQueryString = () => {
        const queryString = Object.entries(formState)
            .filter(([_, value]) => value != undefined && value != "" && value != null)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join("&");
        setFilterUrl(queryString);
        return queryString;
    };
    return {
        getQueryString
    };
};
