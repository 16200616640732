import React from "react";
import { useTranslation } from 'react-i18next';
import "react-toastify/dist/ReactToastify.css";
import DataTablePagination from "../../components/common/DataTablePagination/dataTablePagination";
import TableHeader from "../../components/common/TableHeader/tableHeader";
import ExportButtons from "../../components/ExportButtons";
import Loading from "../../components/loading";
import { useUser } from '../../features/user/userActions';
import FilterContainer from "./components/filterContainer";
import Table from './components/table';
import "./style.css";
const List = () => {
    const { t } = useTranslation();
    const { getAll, users, isLoading, isError, itemsPerPage, total, currentPage, lastPage, setPage, setPerPge } = useUser();
    const breadcrumbLinks = [
        { url: '/', icon: 'bx bx-home-alt', label: t('common.home') },
        { label: t('properties.users') }
    ];
    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }
    if (isError) {
        return <Loading isLoading={false} isError={isError} />;
    }
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <TableHeader
                        breadcrumbLinks={breadcrumbLinks}
                        addLink="/user/create-user"
                        addButtonText={t('properties.addUser')}
                    />
                    <div className="card">
                        <FilterContainer />
                        <div className="card-body">
                            <ExportButtons getFunction={getAll} headers={["name", "surname", "email"]} />
                            <Table
                                data={users}
                            />
                            <DataTablePagination
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                total={total}
                                lastPage={lastPage}
                                handlePageClick={setPage}
                                setPerPge={setPerPge}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default List;
