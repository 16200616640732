import React, { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ApexChart1 from "../../components/apexChart1";
import LineChart from '../../components/LineChart/LineChart';
import PieChart from "../../components/PieChart";
import Amount from "./amount";
import useHomeLoadScripts from '../../hook/scripts/useHomeLoadScripts';
import './style.css';
import Table from "./table";
import DevicesInfo from "./devicesInfo";
import { ToastContainer } from 'react-toastify';
const Index = () => {
    // const { statistics = [], isLoading, isError } = useStatistics();
    const [statistics, setStatics] = useState([]);
    const { t } = useTranslation();
    useHomeLoadScripts()
    // if (isLoading) {
    //     return <Loading isLoading={isLoading} />;
    // }
    // if (isError) {
    //     return <Loading isLoading={false} isError={isError} />;
    // }
    return (
        <HelmetProvider>

            <div className="page-wrapper">
                <div className="page-content">
                    <div class="row row-cols-1 row-cols-md-4 row-cols-xl-4">
                        <Amount color={'bg-danger-custom'} amount={23454} text={t("home.todaySuccessfulOrdersAmount")} />
                        <Amount color={'bg-primary-custom'} amount={345432} text={t("home.SuccessfulOrdersAmountWeek")} />
                        <Amount color={'bg-warning-custom'} amount={432343} text={t("home.SuccessfulOrdersAmountMonth")} />
                        <Amount color={'bg-info-custom'} amount={987656} text={t("home.totalSuccessfulOrdersAmount")} />
                    </div>
                    <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-4">
                        <DevicesInfo total={196542} text={t("home.allDevices")} />
                        <DevicesInfo total={43223} text={t("home.activateDevice")} />
                        <DevicesInfo total={87} text={t("home.activateOffline")} />
                        <DevicesInfo total={9987} text={t("home.activateOnline")} />
                    </div>
                    <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                        <div className="col">
                            <ApexChart1 total={statistics['total_rentals']} />
                        </div>
                        <div className="col">
                            <LineChart />
                        </div>
                        <div className="col">
                            {/* <PieChart /> */}
                            <Table />
                        </div>
                        <div className="col">
                            <PieChart />
                        </div>
                    </div>

                </div>
            </div>
        </HelmetProvider>
    )
}

export default Index