import FileButton from "./fileButton"
import ExportPDF from "./exportPDF"
import "./style.css"
const Index = ({ getFunction, headers, excelHeaders }) => {
    return (
        <>
            <div className="row margin-b">
                <div className="col-sm-12 col-md-6">
                    <div className="dt-buttons btn-group">
                        <ExportPDF getFunction={getFunction} headers={headers} />
                        <FileButton getFunction={getFunction} headers={excelHeaders} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index