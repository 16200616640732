import React from 'react';

const InputMultyLang = ({ label, id, name, value, error, onChange, placeholder }) => {
    return (
        <div className="col-md-6">
            <label htmlFor={id} className="form-label">
                {label}
            </label>
            <input
                type="text"
                className={`form-control ${error && error.length > 0 ? 'is-invalid' : ''}`}
                id={id}
                name={name}
                value={value || ''}
                onChange={onChange}
                placeholder={placeholder}
            />
            {error && error.length > 0 && (
                <div className="invalid-feedback">{error.join(', ')}</div>
            )}
        </div>
    );
};

export default InputMultyLang;
