import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';
import { useState } from 'react';

const fetcher = url => apiClient.get(url).then(res => res.data);

export const useSerivePointTypes = () => {
    const [itemsPerPage, setPerPge] = useState(50)
    const [page, setPage] = useState(1);
    const { data, error } = useSWR(`/servicepoint-type?perPage=${itemsPerPage}&page=${page}`, fetcher, {
        revalidateOnFocus: false
    });
    const { data: allservicepointTypes } = useSWR(`/servicepoint-type`, fetcher, {
        revalidateOnFocus: false
    });
    const lastPage = data?.data?.servicepoint_types?.last_page;
    const total = data?.data?.servicepoint_types?.total;
    const servicepointTypes = data?.data?.servicepoint_types?.data;

    const createSerivePointType = async (newData) => {
        try {
            const response = await apiClient.post('/servicepoint-type', newData);
            mutate(`/servicepoint-type?perPage=${itemsPerPage}&page=${page}`)
            return response.data
        } catch (err) {
            return err.response.data
        }

    };

    const updateSerivePointType = async (id, formData) => {
        try {
            const response = await apiClient.put(`/servicepoint-type/${id}`, formData);
            mutate(`/servicepoint-type?perPage=${itemsPerPage}&page=${page}`)
            mutate(`/servicepoint-type/${id}`)
            return response.data
        } catch (err) {
            return err.response.data
        }
    };

    const deleteSerivePointType = async (id) => {
        try {
            const response = await apiClient.delete(`/servicepoint-type/${id}`);
            mutate(`/servicepoint-type?perPage=${itemsPerPage}&page=${page}`)
            return response;
        } catch (err) {
            return err.response.data
        }
    };

    const getSerivePointTypeById = async (servicePoinTypeId) => {
        const response = await apiClient.get(`/servicepoint-type/${servicePoinTypeId}`);
        return response.data;
    };
    const getAll = async () => {
        try {
            const response = await apiClient.get(`/servicepoint-type`);
            mutate(`/servicepoint-type`);
            return Array.isArray(response?.data?.data?.servicepoint_types) ? response?.data?.data?.servicepoint_types : [];
        } catch (err) {
            return err.response.data;
        }
    }
    return {
        allservicepointTypes: Array.isArray(allservicepointTypes?.data?.servicepoint_types) ? allservicepointTypes?.data?.servicepoint_types : [],
        servicepointTypes,
        isLoading: !error && !data,
        isError: error,
        itemsPerPage,
        lastPage,
        currentPage: page,
        total,
        setPage,
        isLoading: !error && !data,
        createSerivePointType,
        updateSerivePointType,
        deleteSerivePointType,
        getSerivePointTypeById,
        mutate,
        getAll,
        setPerPge
    };
};
