import { useState } from 'react';
import Resizer from 'react-image-file-resizer';
import apiClient from '../../utils/axiosConfig';

const useImageUpload = () => {
    const [file, setFile] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const resizeImage = (file) => {
        return new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
                file,
                800,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                'base64'
            );
        });
    };
    const base64ToBlob = (base64, mimeType) => {
        const byteString = atob(base64.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
        }
        return new Blob([intArray], { type: mimeType });
    };

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);

        if (selectedFile) {
            setIsLoading(true);
            try {
                const resizedImageBase64 = await resizeImage(selectedFile);


                const resizedImageBlob = base64ToBlob(resizedImageBase64, 'image/jpeg');

                const formData = new FormData();
                formData.append('image', resizedImageBlob, selectedFile.name);

                const response = await apiClient.post('/upload-image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                setImageUrl(response.data.data.image_url);
                setUploadError(null);
            } catch (error) {
                console.error(error);
                setUploadError(error.response?.data || 'Error resizing or uploading image');
            } finally {
                setIsLoading(false);
            }
        }
    };
    return {
        file,
        uploadError,
        imageUrl,
        isLoading,
        setImageUrl,
        handleFileChange,
    };
};

export default useImageUpload;
