const Index = ({ handleCloseModal, imageUrl }) => {


    return (
        <div className="modal-overlay" onClick={handleCloseModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>

                <img className="modal-image" src={imageUrl} alt="Detail View" />

                <button className="close-button" onClick={handleCloseModal}>
                    ×
                </button>
            </div>
        </div>
    );
};

export default Index;
