import React from 'react';

const Button = ({ label, onClick, isLoading, disabled }) => {
    return (
        <div className="col-12">
            <button
                type="button"
                onClick={onClick}
                className="btn btn-primary px-4"
                disabled={disabled || isLoading}
            >
                {isLoading ? "Loading ..." : label}
            </button>
        </div>
    );
};

export default Button;
