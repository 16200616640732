import { useState } from 'react';
import { useArea } from '../../../features/area/useArea';

export const useOnUpdatePostClicked = (formState = {}, id) => {

    const { update } = useArea();
    const [response, setResponse] = useState();
    const [loading, setLoading] = useState(false);
    const [formError, setformError] = useState();
    const onUpdatePostClicked = async () => {
        setLoading(true);
        try {
            const formData = {
                id: formState.id,
                name: formState.name,
            };
            const response = await update(id, formData);
            setResponse(response);
            setformError(response.errors)
        } catch (error) {
            const errorResponse = error?.data?.errors || { message: 'Bilinmeyen hata oluştu' };
            setResponse(errorResponse);
            return errorResponse;
        } finally {
            setLoading(false);
        }
    };

    return {
        response,
        loading,
        formError,
        onUpdatePostClicked,
    };
};
