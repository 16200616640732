import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './style.css';

const Index = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/');
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="wrapper">
            <div className="error-404 d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="card">
                        <div className="row g-0">
                            <div className="col-xl-5">
                                <div className="card-body p-4">
                                    <h1 className="display-1"><span className="text-white">5</span><span className="text-white">0</span><span className="text-white">0</span></h1>
                                    <h2 className="font-weight-bold display-4"> {t('common.no_permissions')}</h2>
                                    <p></p>
                                    <div className="mt-5">
                                        <button onClick={goHome} className="btn btn-lg btn-light px-md-5 radius-30"> {t('common.go_home')}</button>
                                        <button onClick={goBack} className="btn btn-lg btn-white ms-3 px-md-5 radius-30"> {t('common.back')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7">
                                <img src="assets/images/errors-images/505-error.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
