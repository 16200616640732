import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Index = ({ items, maxVisible = 5 }) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = (e) => {
        e.preventDefault()
        setIsExpanded((prev) => !prev);
    };

    const visibleItems = isExpanded ? items : items.slice(0, maxVisible);

    return (
        <span>
            {visibleItems.join(", ")}{" "}
            {items.length > maxVisible && (
                <button
                    onClick={(e) => { toggleExpansion(e) }}
                    style={{ color: "rgb(164 223 206)", cursor: "pointer", background: "none", border: "none", padding: 0 }}
                >
                    {isExpanded ? t('properties.show_less') : t('properties.show_more')}
                </button>
            )}
        </span>
    );
};

export default Index;
