import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';
import { useState } from 'react';

const fetcher = url => apiClient.get(url).then(res => res.data);

export const useClient = () => {

    const [itemsPerPage, setPerPge] = useState(50)
    const [page, setPage] = useState(1);
    const { data, error } = useSWR(`/client?perPage=${itemsPerPage}&page=${page}`, fetcher, { revalidateOnFocus: false });
    const { data: allClients } = useSWR(`/client`, fetcher, { revalidateOnFocus: false });
    const lastPage = data?.data?.clients?.last_page;
    const total = data?.data?.clients?.total;
    const clients = data?.data?.clients?.data;

    const createClient = async (newData) => {
        try {
            const response = await apiClient.post('/client', newData);
            mutate(`/client?perPage=${itemsPerPage}&page=${page}`);
            return response.data
        } catch (error) {
            return error.response.data
        }
    };

    const updateClient = async (id, updatedData) => {
        try {
            const response = await apiClient.put(`/client/${id}`, updatedData);
            mutate(`/client?perPage=${itemsPerPage}&page=${page}`);
            return response
        } catch (err) {
            return err.response.data
        }
    };

    const deleteClient = async (id) => {
        try {
            const response = await apiClient.delete(`/client/${id}`);
            mutate(`/client?perPage=${itemsPerPage}&page=${page}`);
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    };

    const getClientById = async (servicePointId) => {
        const response = await apiClient.get(`/client/${servicePointId}`);
        return response.data;
    };

    const searchClient = async (queryString) => {
        try {
            const url = queryString ? `/client?perPage=${itemsPerPage}&${queryString}` : `/client?perPage=${itemsPerPage}&page=${page}`;
            const response = await apiClient.get(url);
            mutate(
                `/client?perPage=${itemsPerPage}&page=${page}`,
                {
                    data: {
                        clients: {
                            data: response?.data?.data?.clients?.data || [],
                            current_page: 1,
                            last_page: response?.data?.data?.clients?.last_page,
                            total: response?.data?.data?.clients?.total,
                            per_page: itemsPerPage
                        }
                    },
                    message: 'Success',
                    code: 200
                },
                false
            );

            return response?.data?.data?.clients?.data;
        } catch (err) {
            return err.response?.data;
        }
    };
    const updateStatus = async (id) => {
        try {
            const response = await apiClient.put(`/client/${id}/status`);
            mutate(`/client?perPage=${itemsPerPage}&page=${page}`);
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    };
    const getAll = async () => {
        try {
            const response = await apiClient.get(`/client`);
            mutate(`/client`);
            return Array.isArray(response?.data?.data?.clients) ? response?.data?.data?.clients : [];
        } catch (err) {
            return err.response.data;
        }
    }
    return {
        allClients: Array.isArray(allClients?.data?.clients) ? allClients?.data?.clients : [],
        clients,
        isLoading: !error && !data,
        isError: error,
        itemsPerPage,
        lastPage,
        currentPage: page,
        total,
        setPage,
        updateStatus,
        createClient,
        updateClient,
        deleteClient,
        searchClient,
        getClientById,
        mutate,
        getAll,
        setPerPge
    };
};
