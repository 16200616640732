import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const TableHeader = ({ breadcrumbLinks, addLink, addButtonText }) => {
    const { t } = useTranslation()
    const reload = (e) => {
        e.preventDefault()
        window.location.reload();
    };

    return (
        <>
            <div className="page-breadcrumb d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            {breadcrumbLinks.map((link, index) => (
                                <li
                                    className={`breadcrumb-item ${index === breadcrumbLinks.length - 1 ? 'active' : ''}`}
                                    aria-current={index === breadcrumbLinks.length - 1 ? 'page' : undefined}
                                    key={index}
                                >
                                    {link.url ? (
                                        <Link to={link.url}>
                                            {link.icon ? <i className={link.icon}></i> : link.label}
                                        </Link>
                                    ) : (
                                        link.label
                                    )}
                                </li>
                            ))}
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                    <div className="btn-group mr-10 ">
                        <button onClick={(e) => reload(e)} type="button" className="btn btn-light btn-light-custom px-5 radius-30">
                            <i class="lni lni-reload reload-custom"></i>
                            {t(('common.reload'))}
                        </button>
                    </div>
                    {addLink && (
                        <div className="btn-group">
                            <Link to={addLink} className="btn btn-light mt-btn">
                                {addButtonText}
                            </Link>
                        </div>

                    )}
                </div>

            </div >
            <hr />
        </>
    );
};

export default TableHeader;
