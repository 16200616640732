import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const useFetchData = (fetchFunction, id, initialFormState, lang) => {
    const [formStateById, setFormStateById] = useState(initialFormState);
    const [fetching, setIsfetching] = useState(false);
    const [fetchError, setFetchError] = useState(null);
    const model = 'servicepoint'
    useEffect(() => {
        const fetchData = async () => {
            setIsfetching(true);
            try {
                const response = await fetchFunction(id);
                const mainDataObject = response.data[model];
                const updatedFormState = {
                    id: mainDataObject.id,
                    relevant_person: mainDataObject.relevant_person || '',
                    servicepoint_type_id: mainDataObject.servicepoint_type_id || null,
                    area_id: mainDataObject.area_id || null,
                    name: {
                        az: mainDataObject.name?.az || '',
                        en: mainDataObject.name?.en || '',
                        ru: mainDataObject.name?.ru || ''
                    },
                    address: {
                        az: mainDataObject.address?.az || '',
                        en: mainDataObject.address?.en || '',
                        ru: mainDataObject.address?.ru || ''
                    },
                    image: mainDataObject.image || '',
                    opening_time: formatTime(mainDataObject.opening_time) || '',
                    closing_time: formatTime(mainDataObject.closing_time) || '',
                    phone: mainDataObject.phone || '',
                    latitude: mainDataObject.latitude || null,
                    longitude: mainDataObject.longitude || null
                };
                setFormStateById(updatedFormState)
            } catch (err) {
                toast.error(`Error: ${err.message}`);
                setFetchError(err.message);
            } finally {
                setIsfetching(false);
            }
        };

        fetchData();
    }, []);

    const formatTime = (time) => {
        if (!time) return '';
        return time.endsWith(':00') ? time.slice(0, -3) : time;
    }
    return { formStateById, setFormStateById, fetching, fetchError };
};
export default useFetchData;
