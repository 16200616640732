import { useState } from 'react';
import { useServicePoints } from '../../../features/servicePoint/useServicePoints';

export const useOnUpdatePostClicked = (formState = {}, imageUrl, id) => {

    const { updateServicePoint } = useServicePoints();
    const [response, setResponse] = useState();
    const [loading, setLoading] = useState(false);
    const [formError, setformError] = useState();

    const onUpdatePostClicked = async () => {
        setLoading(true);
        try {
            const formData = {
                name: formState.name,
                relevant_person: formState.relevant_person,
                servicepoint_type_id: formState.servicepoint_type_id,
                area_id: formState.area_id,
                address: formState.address,
                phone: formState.phone,
                latitude: formState.latitude,
                longitude: formState.longitude,
                opening_time: formState.opening_time,
                closing_time: formState.closing_time,
                image: imageUrl || formState.image,
            };
            const response = await updateServicePoint(id, formData);
            setResponse(response);
            setformError(response.errors)
        } catch (error) {
            const errorResponse = error?.data?.errors || { message: 'Bilinmeyen hata oluştu' };
            setResponse(errorResponse);
            return errorResponse;
        } finally {
            setLoading(false);
        }
    };

    return {
        response,
        loading,
        formError,
        onUpdatePostClicked,
    };
};
