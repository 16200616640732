
import { useTranslation } from 'react-i18next';
import PopupBasic from '../../components/PopupBasic/PopupBasic';
const Index = () => {
    const { t } = useTranslation();
    return (


        <div class="row">
            <div class="col-xl-12 d-flex">
                <div class="card radius-10 w-100">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <h5 class="mb-1">{t("home.transactionHistory")}</h5>
                                <p class="mb-0 font-13"><i class='bx bxs-calendar'></i>{t("home.last30DaysRevenue")}
                                </p>
                            </div>
                            <div class="dropdown ms-auto">
                                <a class="dropdown-toggle dropdown-toggle-nocaret" href="#"
                                    data-bs-toggle="dropdown">
                                </a>
                            </div>
                        </div>
                        <div class="table-responsive mt-4 custom-scroll">
                            <table class="table align-middle mb-0 table-hover" id="Transaction-History">
                                <thead class="table-light">
                                    <tr>
                                        <th>{t("home.userNickname")}</th>
                                        <th>{t("home.paymentName")}</th>
                                        <th>{t("home.siteName")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="custom-circle">
                                                </div>
                                                <div class="ms-2">
                                                    <h6 class="mb-1 font-14">Payment from Michle Jhon</h6>
                                                    <p class="mb-0 font-13">Refrence Id #8547846</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Jan 10, 2021</td>
                                        <td>+256.00</td>
                                        <td>
                                            <div class="badge rounded-pill bg-light text-white w-100" data-bs-toggle="modal" data-bs-target="#exampleScrollableModal">Detail

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="custom-circle">
                                                </div>
                                                <div class="ms-2">
                                                    <h6 class="mb-1 font-14">Payment from Michle Jhon</h6>
                                                    <p class="mb-0 font-13">Refrence Id #8547846</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Jan 10, 2021</td>
                                        <td>+256.00</td>
                                        <td>
                                            <div class="badge rounded-pill bg-light text-white w-100" data-bs-toggle="modal" data-bs-target="#exampleScrollableModal">Detail

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="custom-circle">
                                                </div>
                                                <div class="ms-2">
                                                    <h6 class="mb-1 font-14">Payment from Michle Jhon</h6>
                                                    <p class="mb-0 font-13">Refrence Id #8547846</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Jan 10, 2021</td>
                                        <td>+256.00</td>
                                        <td>
                                            <div class="badge rounded-pill bg-light text-white w-100" data-bs-toggle="modal" data-bs-target="#exampleScrollableModal">Detail

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="custom-circle">
                                                </div>
                                                <div class="ms-2">
                                                    <h6 class="mb-1 font-14">Payment from Michle Jhon</h6>
                                                    <p class="mb-0 font-13">Refrence Id #8547846</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Jan 10, 2021</td>
                                        <td>+256.00</td>
                                        <td>
                                            <div class="badge rounded-pill bg-light text-white w-100" data-bs-toggle="modal" data-bs-target="#exampleScrollableModal">Detail

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="custom-circle">
                                                </div>
                                                <div class="ms-2">
                                                    <h6 class="mb-1 font-14">Payment from Michle Jhon</h6>
                                                    <p class="mb-0 font-13">Refrence Id #8547846</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Jan 10, 2021</td>
                                        <td>+256.00</td>
                                        <td>
                                            <div class="badge rounded-pill bg-light text-white w-100" data-bs-toggle="modal" data-bs-target="#exampleScrollableModal">Detail

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="custom-circle">
                                                </div>
                                                <div class="ms-2">
                                                    <h6 class="mb-1 font-14">Payment from Michle Jhon</h6>
                                                    <p class="mb-0 font-13">Refrence Id #8547846</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Jan 10, 2021</td>
                                        <td>+256.00</td>
                                        <td>
                                            <div class="badge rounded-pill bg-light text-white w-100" data-bs-toggle="modal" data-bs-target="#exampleScrollableModal">Detail

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="custom-circle">
                                                </div>
                                                <div class="ms-2">
                                                    <h6 class="mb-1 font-14">Payment from Michle Jhon</h6>
                                                    <p class="mb-0 font-13">Refrence Id #8547846</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Jan 10, 2021</td>
                                        <td>+256.00</td>
                                        <td>
                                            <div class="badge rounded-pill bg-light text-white w-100" data-bs-toggle="modal" data-bs-target="#exampleScrollableModal">Detail

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <PopupBasic text="Sifariş Detalları" />
        </div>
    )
}

export default Index