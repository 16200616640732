import React from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Loading = ({ isLoading, isError }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    if (isLoading) {
        return (
            <div className="overlay">
                <div className="spinner-wrapper">
                    <div className="spinner-border" style={{ width: "3rem", height: "3rem" }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        );
    }
    if (isError) {
        if (isError?.response?.data?.code === 500) {
            navigate('/require-role');
            return null;
        } else {
            return (
                <div className="overlay">
                    <div className="error-wrapper">
                        <h3>{t('common.something_went_wrong')}</h3>
                        <button
                            className="btn btn-primary px-4"
                            onClick={() => (window.location.href = '/')}
                        >
                            Go Home
                        </button>
                    </div>
                </div>
            );
        }
    }


    return null;
};

export default Loading;
