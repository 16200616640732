import { useParams, useLocation } from 'react-router-dom';
import { useOnUpdatePostClicked } from './hook/useOnUpdatePostClicked';
import { useHandleInputChange } from '../../hook/useHandleInputChange';
import { useSerivePointTypes } from '../../features/servicePointType/useServicePointType';
import { initialFormState } from './initialStates/update';
import { useInvalidLangs } from '../../hook/useInvalidLangs';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { useState } from 'react';
import ToastNotification from '../../components/toastNotfication';
import InputMultyLang from '../../components/common/Input/inputMultyLang';
import useFetchData from './hook/useFetchData';
import Button from '../../components/common/Button/button';
import Loading from '../../components/loading';
import 'react-toastify/dist/ReactToastify.css';
const Update = () => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language
    const [activeTab, setActiveTab] = useState('az');
    const languages = ['az', 'en', 'ru']
    const location = useLocation();
    const id = location.state || {}
    const { getSerivePointTypeById } = useSerivePointTypes();
    const { formStateById, fetching, fetchError } = useFetchData(getSerivePointTypeById, id, initialFormState, lang);
    const { formState, handleInputChange } = useHandleInputChange(formStateById, fetching);
    const { response, loading, onUpdatePostClicked, formError } = useOnUpdatePostClicked(formState, id);
    const { invalidLangs } = useInvalidLangs(formError, loading, response);
    if (fetching) {
        return <Loading isLoading={fetching} />;
    }
    return (
        <HelmetProvider>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <h6 className="mb-0 text-uppercase table-name">{t("table.updateAdvertisement")}</h6>
                            <hr />
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <ul className="nav nav-tabs">
                                            {languages.map(lang => (
                                                <li className="nav-item" key={lang}>
                                                    <button
                                                        className={`nav-link ${invalidLangs?.includes(lang) ? 'invalid-bg' : ''}   ${activeTab === lang ? 'active' : ''}`}
                                                        onClick={() => setActiveTab(lang)}
                                                    >
                                                        {lang.toUpperCase()}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className="p-4 border rounded">
                                        <form className="row g-3">
                                            <InputMultyLang
                                                label={`${t('properties.name')} (${activeTab})`}
                                                id={`name-${activeTab}`}
                                                name="name"
                                                value={formState.name?.[activeTab] || ''}
                                                error={formError?.[`name.${activeTab}`]}
                                                onChange={(e) => handleInputChange(e, activeTab)}
                                            />
                                            <Button
                                                label={t('properties.save')}
                                                onClick={onUpdatePostClicked}
                                                isLoading={loading}
                                                disabled={loading}
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastNotification response={response || fetchError} />
        </HelmetProvider>
    );
};

export default Update;