import React from 'react';
import { useParams } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../components/common/Button/button';
import InputNumber from '../../components/common/Input/inputNumber';
import InputEmail from '../../components/common/Input/inputEmail';
import InputText from '../../components/common/Input/inputText';
import Loading from '../../components/loading';
import ToastNotification from '../../components/toastNotfication';
import { useHandleInputChange } from '../../hook/useHandleInputChange';
import useFetchData from './hook/useFetchData';
import { useOnUpdatePostClicked } from './hook/useOnUpdatePostClicked';
import { initialState } from './initialStates/create';
import { useClient } from '../../features/client/useClient';
const Update = () => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language
    const { id } = useParams();
    const { getClientById } = useClient();
    const { formStateById, fetching, fetchError } = useFetchData(getClientById, id, initialState, lang);
    const { formState, handleInputChange } = useHandleInputChange(formStateById, fetching);
    const { response, loading, onUpdatePostClicked, formError } = useOnUpdatePostClicked(formState, id);
    if (fetching) {
        return <Loading isLoading={fetching} />;
    }
    return (
        <HelmetProvider>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <h6 className="mb-0 text-uppercase table-name">{t("table.updateAdvertisement")}</h6>
                            <hr />
                            <div className="card">
                                <div className="card-body">
                                    <div className="p-4 border rounded">
                                        <form className="row g-3">
                                            <InputText
                                                name="nickname"
                                                label={t('properties.nickname')}
                                                formError={formError}
                                                value={formState?.nickname}
                                                onChange={handleInputChange}
                                            />

                                            <InputText
                                                name="name"
                                                label={t('properties.name')}
                                                formError={formError}
                                                value={formState?.name}
                                                onChange={handleInputChange}
                                            />

                                            <InputNumber
                                                name="wallet"
                                                label={t('properties.wallet')}
                                                formError={formError}
                                                value={formState?.wallet}
                                                onChange={handleInputChange}
                                            />
                                            <InputText
                                                name="phone"
                                                label={t('properties.phone')}
                                                formError={formError}
                                                value={formState?.phone}
                                                onChange={handleInputChange}
                                            />
                                          
                                            <Button
                                                label={t('properties.save')}
                                                onClick={onUpdatePostClicked}
                                                isLoading={fetching || loading}
                                                disabled={loading}
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastNotification response={response || fetchError} />
        </HelmetProvider>
    );
};

export default Update;