import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    errors: {
        name: '',
        email: '',
        password: '',
        phone: '',
        address: '',
        password_confirmation: ''
    }
};

const inputSlice = createSlice({
    name: 'input',
    initialState,
    reducers: {
        setInputError: (state, action) => {
            const { name, message } = action.payload;
            state.errors[name] = message;
        },
        setInputErrors: (state, action) => {
            const errors = action.payload;
            state.errors = { ...state.errors, ...errors };
        },
        clearInputError: (state, action) => {
            const { name } = action.payload;
            state.errors[name] = '';
        }
    }
});

export const { setInputError, setInputErrors, clearInputError } = inputSlice.actions;

export default inputSlice.reducer;
