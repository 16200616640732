import React from "react";
import { useRoles } from "../../features/roles/useRoles";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from 'react-i18next';
import Loading from "../../components/loading"
const List = () => {
    const { t } = useTranslation();
    const { roles, isLoading, isError } = useRoles();

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    if (isError) {
        return <Loading isLoading={false} isError={isError} />;
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="page-breadcrumb d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">{t("role.tables")}</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to={"/"}>
                                            <i className="bx bx-home-alt"></i>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">{t("role.rolePermissionTable")}</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="ms-auto">

                        </div>
                    </div>
                    <h6 className="mb-0 text-uppercase table-name">{t("role.rolePermission")}</h6>
                    <hr />
                    <div className="card">
                        <div className="card-body">
                            <div className="row margin-b">
                            </div>
                            <div className="table-responsive">
                                <table id="example2" className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t("table.name")}</th>
                                            <th>{t("role.permission")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {roles.map(role => (
                                            <tr key={role.id}>
                                                <td>{role.id}</td>
                                                <td>{role.name}</td>
                                                <td>
                                                    {role.permissions.map(permission => permission.name).join(', ')}
                                                </td>
                                                <td>
                                                    <div className="col">
                                                        <div style={{ justifyContent: "center" }} className="d-flex align-items-center justify-content-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                                                            <div className="font-22 text-white" style={{ paddingInline: "7px" }}>
                                                                <Link to={`/role/set-role-permission/${role.id}`} state={{ role }}>
                                                                    <i className="lni lni-pencil-alt"></i>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default List;
