import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';
import { useState } from 'react';

const fetcher = url => apiClient.get(url).then(res => res.data);

export const useArea = () => {
    const [itemsPerPage, setPerPge] = useState(50)
    const [page, setPage] = useState(1);
    const { data, error } = useSWR(`/area?perPage=${itemsPerPage}&page=${page}`, fetcher, {
        revalidateOnFocus: false
    });
    const { data: allAreas, } = useSWR(`/area`, fetcher, {
        revalidateOnFocus: false
    });
    const lastPage = data?.data?.areas?.last_page;
    const total = data?.data?.areas?.total;
    const areas = data?.data?.areas?.data;

    const create = async (newData) => {
        try {
            const response = await apiClient.post('/area', newData);
            mutate(`/area?perPage=${itemsPerPage}&page=${page}`)
            return response.data
        } catch (err) {
            return err.response.data
        }

    };

    const update = async (id, formData) => {
        try {
            const response = await apiClient.put(`/area/${id}`, formData);
            mutate(`/area?perPage=${itemsPerPage}&page=${page}`)
            mutate(`/area/${id}`)
            return response.data
        } catch (err) {
            return err.response.data
        }
    };

    const deleteArea = async (id) => {
        try {
            const response = await apiClient.delete(`/area/${id}`);
            mutate(`/area?perPage=${itemsPerPage}&page=${page}`)
            return response;
        } catch (err) {
            return err.response.data
        }
    };

    const getById = async (servicePoinTypeId) => {
        const response = await apiClient.get(`/area/${servicePoinTypeId}`);
        return response.data;
    };

    const updateStatus = async (id) => {
        try {
            const response = await apiClient.put(`/area/${id}/status`);
            mutate(`/area?perPage=${itemsPerPage}&page=${page}`);
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    };
    const getAll = async () => {
        try {
            const response = await apiClient.get(`/area`);
            mutate(`/area`);
            return Array.isArray(response?.data?.data?.areas) ? response?.data?.data?.areas : [];
        } catch (err) {
            return err.response.data;
        }
    }
    return {
        allAreas: Array.isArray(allAreas?.data?.areas) ? allAreas?.data?.areas : [],
        areas,
        isLoading: !error && !data,
        isError: error,
        itemsPerPage,
        lastPage,
        currentPage: page,
        total,
        setPage,
        isLoading: !error && !data,
        create,
        update,
        deleteArea,
        getById,
        mutate,
        updateStatus,
        getAll,
        setPerPge
    };
};
