import { useState, useEffect } from 'react';
import apiClient from '../../utils/axiosConfig';

const useVideoUpload = () => {
    const [file, setFile] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [isLoadingVideo, setIsLoadingVideo] = useState(false);

    const handleVideoChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        setFile(selectedFile);
        setIsLoadingVideo(true);
        setUploadError(null);

        try {

            const formData = new FormData();
            formData.append('video', selectedFile, selectedFile.name);
            const response = await apiClient.post('/upload-video', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setVideoUrl(response.data.data.video_url)
        } catch (error) {
            setUploadError(error.message);
        } finally {
            setIsLoadingVideo(false);
        }
    };
    const handleVideoDelete =() => {
        setVideoUrl(null)
    }
    return {
        file,
        uploadError,
        videoUrl,
        setVideoUrl,
        isLoadingVideo,
        handleVideoChange,
    };
};

export default useVideoUpload;
