import React from "react";
import PropTypes from "prop-types";
import FilterButtonComponent from "./FilterButtonComponent";
import ResetButtonComponent from "./ResetButtonComponent";

const FilterBodyComponent = ({children, handleFilter, handleRestFilter}) => {
    return (
        <div className="filter-body py-4 rounded-lg shadow-lg">
            <div className="row">
                {children}
            </div>
            <div className="row mt-4 justify-content-end">
                <div className="col-6 text-end">
                    <FilterButtonComponent onClick={handleFilter}/>
                    <ResetButtonComponent onClick={handleRestFilter}/>
                </div>
            </div>
        </div>
    );
};

FilterBodyComponent.propTypes = {
    children: PropTypes.node.isRequired, // Accepts any JSX as content
};

export default FilterBodyComponent;
