import { useEffect, useState } from 'react';

export function useInvalidLangs(formErrors, loading, response) {
    const [invalidLangs, setInvalidLangs] = useState([]);

    useEffect(() => {
        setInvalidLangs([]);
        if (formErrors && Object.keys(formErrors).length > 0) {
            getInvalidLangs();
        }
    }, [formErrors, loading, response]);

    const getInvalidLangs = () => {
        const langs = [];
        Object.keys(formErrors).forEach((key) => {
            const parts = key.split('.');
            if (parts.length > 1) {
                const lang = parts[1];
                if (['az', 'en', 'ru'].includes(lang) && !langs.includes(lang)) {
                    langs.push(lang);
                }
            }
        });
        setInvalidLangs(langs);
    };

    return {
        invalidLangs
    };
}
