import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
const Index = ({total}) => {
    const { t } = useTranslation();
    const lastWeekData = [135, 160, 210, 186, 139, 196, 156];
    const lastWeekLabels = [
        t("lastWeekLabels.mon"),
        t("lastWeekLabels.tue"),
        t("lastWeekLabels.wed"),
        t("lastWeekLabels.thu"),
        t("lastWeekLabels.fri"),
        t("lastWeekLabels.sat"),
        t("lastWeekLabels.sun")
    ];
    const name = t("home.orderCount")
    const chartOptions = {
        series: [
            {
                name: name,
                data: lastWeekData,
            },
        ],
        chart: {
            foreColor: "rgba(255, 255, 255, 0.50)",
            type: "bar",
            height: 390,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            dropShadow: {
                enabled: false,
                top: 3,
                left: 10,
                blur: 3,
                opacity: 0.1,
                color: "#0d6efd",
            },
            sparkline: {
                enabled: false,
            },
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: "70%",
                },
            },
            bar: {
                horizontal: false,
                columnWidth: "35%",
                endingShape: "rounded",
            },
        },
        markers: {
            size: 0,
            colors: ["#fff"],
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
                size: 7,
            },
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            borderColor: "rgba(255, 255, 255, 0.12)",
            show: true,
        },
        stroke: {
            show: true,
            width: 3,
            curve: "smooth",
        },
        colors: ["#fff"],
        xaxis: {
            categories: lastWeekLabels,
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            theme: "dark",
            y: {
                formatter: function (value) {
                    return "$ " + value + " thousands";
                },
            },
        },
    };

    return (
        <div className="col d-flex">
            <div className="card radius-10 w-100">
                <div className="card-body">
                    <p className="font-weight-bold mb-1">{t("home.orderCount")}</p>
                    <div className="d-flex align-items-center">
                        <div>
                            <h4 className="mb-0">{total}</h4>
                        </div>
                        <div>
                            <p className="mb-0 align-self-center font-weight-bold ms-2">
                                 <i className="bx bxs-up-arrow-alt mr-2"></i>
                            </p>
                        </div>
                    </div>
                    <Chart options={chartOptions} series={chartOptions.series} type="bar" height={350} />
                </div>
            </div>
        </div>
    );
}

export default Index;
