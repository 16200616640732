import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import { loginUser } from '../../../features/userAuth/authSlice';
import { getLocalStorage, setLocalStorage } from '../../../utils/cookieUtils';
import "../styles.css";
import { useAppSetting } from '../../../features/appSetting/useAppSetting';
import RememberMe from './rememberMe';
const Index = () => {
    const userRef = useRef(null);
    const errRef = useRef(null);
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { status, error } = useSelector(state => state.auth);

    useEffect(() => {
        userRef.current?.focus();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const loginData = {
                email: user.toLowerCase(),
                password: pwd
            };
            const response = await dispatch(loginUser(loginData)).unwrap();
            if (response?.data.token && response?.data?.user) {
                setLocalStorage('authToken', response?.data.token);
                setLocalStorage('authUser', response?.data?.user);
                if (getLocalStorage('authToken') && getLocalStorage('authUser')) {
                    setUser('');
                    setPwd('');
                    navigate('/');
                }
            } else {
                toast.success('Login faild!');
            }
        } catch (err) {
            if (!error) {
                setErrMsg(setErrMsg.errMsg);
                toast.error('Incorrect email or password');
            } else if (error === '400') {
                setErrMsg('Missing Username or Password');
                toast.error('Missing Username or Password');
            } else if (error === '401') {
                setErrMsg(error);
                toast.error(error);
            } else {
                setErrMsg('Login Failed');
                toast.error('Login Failed');
            }
            errRef.current?.focus();
        }
    };

    const handleUserInput = (e) => setUser(e.target.value);
    const handlePwdInput = (e) => setPwd(e.target.value);
    const [isShowPassword, setisShowPassword] = useState(false)

    const handleShowPassword = () => {
        setisShowPassword(prev => !prev)
    }
    const { setting } = useAppSetting()
    return (
        <HelmetProvider>
            <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
                <div className="container">
                    <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                        <div className="col mx-auto">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <div className="p-4">
                                        <div className="mb-3 text-center">
                                            <img src={setting?.app_logo ? setting.app_logo : "/assets/images/app/bakuPower.png"} width="60" alt="" />
                                        </div>
                                        <div className="text-center mb-4">
                                            <h5>Baku Power Admin</h5>
                                            <p className="mb-0">Please log in to your account</p>
                                        </div>
                                        <div className="form-body">
                                            <form className="row g-3" >
                                                <div className="col-12">
                                                    <label htmlFor="inputEmailAddress" className="form-label">Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="inputEmailAddress"
                                                        placeholder="jhon@example.com"
                                                        value={user}
                                                        onChange={handleUserInput}
                                                        ref={userRef}
                                                        autoComplete="off"
                                                        required
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <label htmlFor="inputChoosePassword" className="form-label">Password</label>
                                                    <div className="input-group" id="show_hide_password">
                                                        <input
                                                            type={isShowPassword ? "text" : "password"}
                                                            className="form-control border-end-0"
                                                            id="inputChoosePassword"
                                                            value={pwd}
                                                            placeholder="Enter Password"
                                                            onChange={handlePwdInput}
                                                            aria-describedby="inputGroupPrepend3 validationServer02Feedback"
                                                        />
                                                        <a onClick={handleShowPassword} href="javascript:;" className="pass-input-show input-group-text bg-transparent">
                                                            <i className={`bx bx-${isShowPassword ? "show" : "hide"}`}></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <RememberMe />
                                                <div className="col-12">
                                                    <div className="d-grid">
                                                        <button onClick={handleSubmit} type="submit" className="btn btn-light mt-btn">Sign in</button>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </HelmetProvider>
    );
}

export default Index