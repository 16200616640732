import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useParams } from 'react-router-dom';
import { useRoles } from "../../features/roles/useRoles";
import './style.css';
import { useTranslation } from 'react-i18next';
const RolePermissionForm = () => {
    const { t } = useTranslation();
    const { roleId } = useParams();
    const { permissions = [], setRolePermission, role } = useRoles(roleId);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formState, setFormState] = useState({
        selectedPermissions: [],
        permissions: []
    });

    useEffect(() => {
        if (role !== undefined && role.permissions !== undefined) {
            setFormState({
                selectedPermissions: role !== undefined ? role.permissions.map(permission => permission.name) : [],
                permissions: role !== undefined ? role.permissions.map(permission => permission.name) : []
            });
        }
    }, [role]);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const onSelectChange = (permission) => {
        setFormState(prevState => {
            if (prevState.selectedPermissions.includes(permission.name)) {
                toast.info('Permission already added.');
                return prevState;
            }
            return {
                ...prevState,
                selectedPermissions: [...prevState.selectedPermissions, permission.name]
            };
        });
        setDropdownOpen(false);
    };


    const removePermission = (permissionName) => {
        setFormState(prevState => ({
            ...prevState,
            selectedPermissions: prevState.selectedPermissions.filter(p => p !== permissionName)
        }));
    };

    const onSavePostClicked = async () => {
        setIsSubmitting(true);
        try {
            await setRolePermission(roleId, {
                permissions: formState.selectedPermissions
            });
            toast.success('Permissions assigned successfully!');
        } catch (err) {
            if (err.response && err.response.data) {
                toast.error('Failed to assign permissions. Please check the form for errors.');
            } else {
                toast.error('An unexpected error occurred.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    };




    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = "/assets/js/jquery.min.js";
        script1.async = false;
        document.body.appendChild(script1);

        const script3 = document.createElement('script');
        script3.src = "/assets/plugins/simplebar/js/simplebar.min.js";
        script3.async = false;
        document.body.appendChild(script3);

        const script4 = document.createElement('script');
        script4.src = "/assets/plugins/metismenu/js/metisMenu.min.js";
        script4.async = false;
        document.body.appendChild(script4);

        // const script10 = document.createElement('script');
        // script10.src = "/assets/js/app.js";
        // script10.async = false;
        // document.body.appendChild(script10);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script3);
            document.body.removeChild(script4);
            // document.body.removeChild(script10);
        }
    }, []);
    return (
        <HelmetProvider>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <h6 className="mb-0 text-uppercase table-name">{t("role.assignPermissions")}</h6>
                            <hr />
                            <div className="d-flex justify-content-end mb-3">
                                <div className="btn-group">
                                    <Link to="/role/list" className="btn btn-light mt-btn">{t("role.allRoles")}</Link>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="p-4 border rounded">
                                        <form className="row g-3">
                                            <div className="col">
                                                <label htmlFor="permissions" className="form-label">{t("role.permissions")}</label>
                                                <div className="input-container">
                                                    <input
                                                        name='permissions'
                                                        value={formState.selectedPermissions.join(', ')}
                                                        type="text"
                                                        className="form-control"
                                                        id="permissions"
                                                        onClick={toggleDropdown}
                                                        readOnly
                                                    />
                                                    {dropdownOpen && (
                                                        <div className="dropdown-menu">
                                                            {permissions.map(permission => (
                                                                <button
                                                                    key={permission.id}
                                                                    type="button"
                                                                    className="dropdown-item"
                                                                    onClick={() => onSelectChange(permission)}
                                                                >
                                                                    {permission.name}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    )}
                                                    <div className="selected-permissions">
                                                        {formState.selectedPermissions.map((permission, index) => (
                                                            <div key={index} className="permission-tag">
                                                                {permission}
                                                                <span className="remove-icon" onClick={() => removePermission(permission)}>x</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button
                                                    type="button"
                                                    onClick={onSavePostClicked}
                                                    className="btn btn-primary px-4"
                                                    disabled={isSubmitting}
                                                >
                                                    {t("role.save")}
                                                </button>
                                            </div>
                                        </form>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HelmetProvider>
    );
}

export default RolePermissionForm;
