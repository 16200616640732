import React from 'react';
import { useTranslation } from 'react-i18next';
import "./style.css";

const Index = ({ text, data }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="modal fade custom-fullModal" id="exampleFullScreenModal2" >
                <div className="modal-dialog modal-fullscreen custom-fullModal">
                    <div className="modal-content custom-fullModal">
                        <div className="modal-header custom-fullModal">
                            <h5 className="modal-title logs-header">{text}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body custom-fullModal">
                            <div>
                                <h6 className="mb-0 text-uppercase">{t('logs.logsTable')}</h6>
                                <hr />
                                <div className="card">
                                    <div className="card-body table-responsive">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>{t('logs.field')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data ? (
                                                    <tr>
                                                        <td>{data.someField || 'Məlumat yoxdur'}</td> {/* Məlumatı burada göstəririk */}
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td>{t('logs.noData')}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
