import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "react-multi-select-component";
import ShowMoreComponent from "../ShowMore/index"; // Import ShowMoreComponent
import './style.css';

const MultipleSelect = ({ options, name, onSelectionChange, selectedValues, label }) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;

    const sellectedValues = Array.isArray(selectedValues)
        ? selectedValues.map(option => ({
            id: option?.id,
            value: option?.id,
            label: option?.name?.[lang]
        }))
        : [];

    const [selected, setSelected] = useState(sellectedValues);

    const transformedOptions = options.map(option => ({
        id: option.id,
        value: option.id,
        label: option.name[lang]
    }));

    useEffect(() => {
        const values = selected.map(obj => obj.id);
        onSelectionChange(values);
    }, [selected]);

    return (
        <div className="col-md-6">
            <label className="form-label">
                {label}
            </label>
            <div>
                <ShowMoreComponent items={selected.map(obj => obj.label)} maxVisible={5} />
            </div>
            <MultiSelect
                options={transformedOptions}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
                name={name}
                className="multi-select-container"
            />
        </div>
    );
};

export default MultipleSelect;
