
import { useTranslation } from 'react-i18next';
const TableHeaderProperties = () => {

    const { t } = useTranslation();
    return (
        <tr>
            <th>#</th>
            <th>{t('properties.image')}</th>
            <th>{t('properties.name')}</th>
            <th>{t('properties.address')}</th>
            <th>{t('properties.area')}</th>
            <th>{t('properties.relevant_person')}</th>
            <th>{t('properties.servicepoint_number')}</th>
            <th>{t('properties.latitude')}</th>
            <th>{t('properties.longitude')}</th>
            <th>{t('properties.phone')}</th>
            <th>{t('properties.opening_time')}</th>
            <th>{t('properties.closing_time')}</th>
            <th>{t('properties.status')}</th>
            <th colspan="2">{t('properties.actions')}</th>
        </tr>
    )
}

export default TableHeaderProperties