import { useEffect, useState } from 'react';

export const useHandleInputChange = (initialState = {}, loading = false) => {
    const [formState, setFormState] = useState(initialState);
    useEffect(() => {
        setFormState(initialState)
    }, [loading])
    const handleInputChange = (e, lang = null, obj) => {
        const { name, value, type, options, multiple, checked } = e.target;

        if (lang) {
            setFormState((prev) => ({
                ...prev,
                [name]: {
                    ...prev[name],
                    [lang]: value,
                },
            }));
        } else if (type === 'time') {
            const formattedValue = value.split(':').slice(0, 2).join(':');
            setFormState((prevState) => ({
                ...prevState,
                [name]: formattedValue,
            }));
        } else if (multiple) {
            const selectedValues = Array.from(options)
                .filter(option => option.selected)
                .map(option => option.value);
            setFormState(prevState => ({
                ...prevState,
                [name]: selectedValues
            }));
        } else if (type === 'checkbox') {
            setFormState((prevState) => ({
                ...prevState,
                [name]: checked ?? "true",
            }));
        } else {
            setFormState((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    return {
        formState,
        setFormState,
        handleInputChange,
    };
};