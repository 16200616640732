import React from "react";
import { useUser } from "../../../features/user/userActions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const Logout = () => {
    const { logout } = useUser()
    const navigate = useNavigate()
    const { t } = useTranslation();
    const handleLogout = async () => {
        try {
            await logout();
            navigate("/login");
        } catch (error) {
            toast.error(error.message || "An error occurred during logout");
        }
    };

    return (
        <li>
            <button className="dropdown-item d-flex align-items-center" onClick={handleLogout}>
                <i className="bx bx-log-out-circle"></i><span>{t("navbar.logout")}</span>
            </button>
        </li>
    );
};

export default Logout;
