import './style.css';

const MoreInfo = ({ title, infoData }) => {
    return (
        <div className="more-info-container">
            <h6 className="mb-0 text-uppercase">{title}</h6>
            <div className="card">
                <div className="card-body">
                    <div className="row row-cols-1 row-cols-lg-4 g-3 align-items-center custom-items-center">
                        {infoData.map((item, index) => (
                            <div className="col" key={index}>
                                <div className={`shadow${index === 0 ? '-none' : index === 1 ? '-sm' : ''} p-4 rounded custom-text`}>
                                    <span>{item.text}</span>
                                    <span>{item.data}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MoreInfo;
