import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.css'
const InputSelect = ({ name, label, options, value, onChange, formError, lang, field, className = "col-md-6", editable = false }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={className}>
                <label htmlFor={name} className="form-label">{label}</label>
                <select
                    name={name}
                    onChange={onChange}
                    value={value || ''}
                    disabled={editable}
                    className={`form-select ${formError && formError[name] && formError[name].length > 0 ? 'is-invalid' : ''}`}
                    id={name}
                >
                    <option value=''>{t('properties.choose')}...</option>
                    {options?.map((option) => (
                        <option key={option.id} value={option.id}>
                            {field ? option?.[field] : option.name[lang]}
                        </option>
                    ))}
                </select>

                {/* Error Message */}
                {formError?.[name] && formError[name]?.length > 0 && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', marginTop: '5px' }}>
                        {formError[name].map((error, index) => (
                            <div key={index}>{error}</div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default InputSelect;
