import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const useFetchData = (fetchFunction, id, initialFormState, lang) => {
    const [formStateById, setFormStateById] = useState(initialFormState);
    const [fetching, setIsfetching] = useState(false);
    const [fetchError, setFetchError] = useState(null);
    const model = 'advertisement'
    useEffect(() => {
        const fetchData = async () => {
            setIsfetching(true);
            try {
                const response = await fetchFunction(id);
                const mainDataObject = response.data[model];
                const updatedFormState = {
                    id: mainDataObject.id,
                    name: mainDataObject.name,
                    file_path: mainDataObject.file_path,
                    servicepoint_ids: mainDataObject?.servicepoints?.map(service => service.id),
                    status: mainDataObject.status,
                    file_type: mainDataObject.file_type,
                    servicepoints: mainDataObject.servicepoints,
                }
                setFormStateById(updatedFormState)
            } catch (err) {
                toast.error(`Error: ${err.message}`);
                setFetchError(err.message);
            } finally {
                setIsfetching(false);
            }
        };

        fetchData();
    }, []);
    return { formStateById, setFormStateById, fetching, fetchError };
};
export default useFetchData;
