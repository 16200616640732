import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';

const Index = ({ getFunction, headers = [] }) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;

    const transformDataForExport = (data) => {
        const dataHeaders = headers.length > 0 ? headers : Object.keys(data[0] || {});
        
        return data.map(item => {
            const transformedItem = {};

            dataHeaders.forEach(header => {
                if (item.hasOwnProperty(header)) {
                    const value = item[header];

                    if (value && typeof value === 'object' && lang in value) {
                        transformedItem[header] = value[lang];
                    } else if (Array.isArray(value)) {
                        transformedItem[header] = value.map(subItem => transformDataForExport([subItem])[0]);
                    } else {
                        transformedItem[header] = value;
                    }
                }
            });

            return transformedItem;
        });
    };

    const handleExportExcel = async () => {
        const data = await getFunction();
        const localizedData = transformDataForExport(data);
        const ws = XLSX.utils.json_to_sheet(localizedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });
        saveAs(blob, 'data.xlsx');
    };

    return (
        <button
            className="btn btn-light mt-btn export-buttons"
            tabIndex="0"
            aria-controls="example2"
            type="button"
            onClick={handleExportExcel}
        >
            <span>Excel</span>
        </button>
    );
};

export default Index;
