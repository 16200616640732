const Index = ({ total, text, percent }) => {
    return (
        <>
            <div className="col">
                <div className="card radius-10">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div>
                                <p className="mb-0">{text}</p>
                                <h4 className="my-1">{total}</h4>
                                {
                                    percent &&
                                    <p className="mb-0 font-13"><i className='bx bxs-up-arrow align-middle'></i>
                                        100%
                                    </p>
                                }
                            </div>
                            <div className="widgets-icons ms-auto"><i className='bx bxs-wallet'></i>
                            </div>
                        </div>
                        <div id="chart1"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index