import React, { useState } from "react";
import Modal from "../../Modal/modal";
import { t } from "i18next";

const ActionButtons = ({ editState, text, btntext = 'properties.detail' }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="col full-width-height">
                <div onClick={handleOpenModal} class="btn-group"><a class="btn btn-light mt-btn">{t(btntext)}</a></div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                data={editState}
                text={text}
            />
        </>
    );
};

export default ActionButtons;
