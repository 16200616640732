import React from "react";
import Chart from "react-apexcharts";

const Index = () => {
    const chartOptions = {
        series: [44.4234234, 55.5345345, 13.8765432, 43.234567, 22.345678], // Örnek sayılar
        chart: {
            foreColor: "rgba(255, 255, 255, 0.65)",
            height: 380,
            type: "pie",
        },
        colors: ["#673ab7", "#32ab13", "#f02769", "#ffc107", "#198fed"],
        labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        height: 360,
                    },
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
        tooltip: {
            y: {
                formatter: function (val) {
                    return Math.round(val * 100) / 100;
                },
            },
        },
    };

    return (
        <div className="card">
            <div className="card-body">
                <h6 className="mb-0 text-uppercase table-name">Pie Chart</h6>
                <hr />
                <Chart options={chartOptions} series={chartOptions.series} type="pie" height={380} />
            </div>
        </div>
    );
};

export default Index;
