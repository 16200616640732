import { useEffect } from 'react';

const useHomeLoadScripts = () => {
    useEffect(() => {
        const scripts = [
            "/assets/js/jquery.min.js",
            "/assets/plugins/simplebar/js/simplebar.min.js",
            "/assets/plugins/metismenu/js/metisMenu.min.js",
            "/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/assets/plugins/datatable/js/dataTables.bootstrap5.min.js"
        ];

        const scriptElements = scripts.map(src => {
            const script = document.createElement('script');
            script.src = src;
            script.async = false;
            document.body.appendChild(script);
            return script;
        });

        return () => {
            scriptElements.forEach(script => {
                document.body.removeChild(script);
            });
        };
    }, []);
};

export default useHomeLoadScripts;
