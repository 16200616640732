import { useState } from 'react';
import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';
import { removeLocalStorage } from '../../utils/cookieUtils';
import { useTranslation } from 'react-i18next';
const fetcher = url => apiClient.get(url).then(res => res.data);
export const useUser = () => {
    const { t } = useTranslation()
    const [itemsPerPage, setPerPge] = useState(50)
    const [page, setPage] = useState(1);
    const { data, error } = useSWR(`/user?perPage=${itemsPerPage}&page=${page}`, fetcher, { revalidateOnFocus: false });

    const lastPage = data?.data?.users?.last_page;
    const total = data?.data?.users?.total;
    const users = data?.data?.users?.data;

    const addUser = async (newData) => {
        try {
            const response = await apiClient.post('/user', newData);
            mutate(`/user?perPage=${itemsPerPage}&page=${page}`);
            return response.data
        } catch (error) {
            return error.response.data

        }
    };


    const updateUser = async (id, updatedData) => {
        try {
            const response = await apiClient.put(`/user/${id}`, updatedData);
            mutate(`/user?perPage=${itemsPerPage}&page=${page}`);
            mutate(`/user/${id}`);
            return response
        } catch (err) {
            return err.response.data
        }
    };

    const updateUserProfile = async (id, updatedData) => {
        try {
            const response = await apiClient.put(`/user/${id}`, updatedData);
            mutate(`/user?perPage=${itemsPerPage}&page=${page}`);
            return response
        } catch (err) {
            return err.response.data
        }
    };

    const deleteUser = async (id) => {
        try {
            const response = await apiClient.delete(`/user/${id}`);
            mutate(`/user?perPage=${itemsPerPage}&page=${page}`);
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    };

    const getUserById = async (userId) => {
        const response = await apiClient.get(`/user/${userId}`);
        return response.data;
    };

    const setUserRoles = async (userId, roles) => {
        const response = await apiClient.put(`/set-user-role/${userId}`, roles);
        mutate(`/user?perPage=${itemsPerPage}&page=${page}`);
        return response.data;
    };

    const logout = async () => {
        try {
            const response = await apiClient.post(`/logout`);
            removeLocalStorage('authToken');
            removeLocalStorage('authUser');
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data?.message || "Logout failed");
        }
    };

    const validatedToken = async (token) => {
        const response = await apiClient.post(`/validate-token/${token}`);
        return response;
    };

    const searchData = async (queryString) => {
        try {
            const url = queryString ? `/user?perPage=${itemsPerPage}&${queryString}` : `/user?perPage=${itemsPerPage}&page=${page}`;
            const response = await apiClient.get(url);
            mutate(
                `/user?perPage=${itemsPerPage}&page=${page}`,
                {
                    data: {
                        users: {
                            data: response?.data?.data?.users?.data || [],
                            current_page: 1,
                            last_page: response?.data?.data?.users?.last_page,
                            total: response?.data?.data?.users?.total,
                            per_page: itemsPerPage
                        }
                    },
                    message: 'Success',
                    code: 200
                },
                false
            );

            return response?.data?.data?.clients?.data;
        } catch (err) {
            return err.response?.data;
        }
    };

    const updateStatus = async (id) => {
        try {
            const response = await apiClient.put(`/user/${id}/status`);
            mutate(`/user?perPage=${itemsPerPage}&page=${page}`);
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    };
    const getUserObject = (user) => {
        user = {
            name: user.name,
            surname: user.surname,
            email: user.email,
            role: user.roles.map(role => role.name).join(', '),
            status: user.status == 0 ? t('properties.inactive') : t('properties.active')
        }
        return user;
    }

    const getAll = async () => {
        try {
            const response = await apiClient.get(`/user`);
            mutate(`/user`);
            return Array.isArray(response?.data?.data?.users) ? response?.data?.data?.users : [];
        } catch (err) {
            return err.response.data;
        }
    }
    return {
        users,
        currentPage: page,
        addUser,
        isLoading: !error && !data,
        total,
        isError: error,
        lastPage,
        setPage,
        itemsPerPage,
        updateUser,
        validatedToken,
        updateStatus,
        searchData,
        logout,
        setUserRoles,
        getUserById,
        deleteUser,
        updateUserProfile,
        updateUser,
        getUserObject,
        setPerPge,
        getAll
    }
}