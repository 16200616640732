import React, { useState } from "react";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import ToastNotification from "../toastNotfication/index"
const ToggleSwitch = ({ initialState, onChange, id }) => {
  const [isEnabled, setIsEnabled] = useState(initialState == 0 ? false : true);
  const [response, setResponse] = useState()
  const handleToggle = async () => {
    const newState = !isEnabled;
    setIsEnabled(newState);
    try {
      const response = await onChange(id);
      if (response?.code == 200) {
        setResponse(response)
      } else {
        setResponse(response)
        setIsEnabled(!newState);
      }
    } catch (error) {
      NotificationManager.error("Serverlə əlaqədə problem baş verdi", "Xəta!");
      setIsEnabled(!newState);
    }
  };

  return (
    <>
      <div className="form-check form-switch full-width-height" onClick={handleToggle}>
        <input
          checked={isEnabled}
          className="form-check-input"
          type="checkbox"
          id="flexSwitchCheckDefault"
          readOnly
        />
      </div>
      <ToastNotification response={response} />
    </>
  );
};

export default ToggleSwitch;
