import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FullscreenModal from "../../components/FullScreenModal/FullScreenModal";
import { getLocalStorage } from '../../utils/cookieUtils';
import Logout from "./Components/Logout";
import Socials from "./Components/socials";
import Language from './Language';
import { useAppSetting } from "../../features/appSetting/useAppSetting";
const Header = () => {
    const { t } = useTranslation();
    const user = getLocalStorage('authUser');
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const { setting } = useAppSetting();
    
    return (
        <header>
            <div className={`topbar d-flex align-items-center ${isScrolled ? 'bg-dark' : ''}`}>
                <nav className="navbar navbar-expand gap-3">
                    <div className="topbar-logo-header d-none d-lg-flex">
                        <div className="">
                            <img src={setting?.app_logo ? setting.app_logo : "/assets/images/app/bakuPower.png"} className="logo-icon" alt="logo icon" />
                        </div>
                        <div className="">
                            <h4 className="logo-text">Baku Power</h4>
                        </div>
                    </div>
                    <div className="mobile-toggle-menu d-block d-lg-none" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar"><i className='bx bx-menu'></i></div>
                    {/* <div className="search-bar flex-grow-1">
                        <div className="position-relative search-bar-box">
                            <input type="text" className="form-control search-control" placeholder="Type to search..." />
                            <span className="position-absolute top-50 search-show translate-middle-y"><i
                                className='bx bx-search'></i></span>
                            <span className="position-absolute top-50 search-close translate-middle-y"><i
                                className='bx bx-x'></i></span>
                        </div>
                    </div> */}
                    <div className="top-menu ms-auto">
                        <ul className="navbar-nav align-items-center gap-1">
                            <Language />
                            <Socials setting={setting} />
                            {/* <li className="nav-item dropdown dropdown-app">
                                <div onClick={() => handleModalOpen(null)} className="font-22 text-white" data-bs-toggle="modal" data-bs-target="#exampleFullScreenModal2">
                                    <i className="fadeIn animated bx bx-list-ul"></i>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                    <div className="user-box dropdown px-3">
                        <a className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret"
                            href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {user?.profile_picture != null ? (
                                <img
                                    src={user.profile_picture}
                                    className="user-img "
                                    alt="user avatar"
                                    style={{ objectFit: 'cover' }}
                                />
                            ) : (
                                <img
                                    src="/assets/images/avatars/profile.jpg"
                                    className="user-img"
                                    alt="user avatar"
                                />
                            )}


                            <div className="user-info">
                                {user ? (
                                    <p className="user-name mb-0">{user.name}</p>
                                ) : (
                                    <p className="user-name mb-0">Please log in</p>
                                )}

                            </div>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <Link className="dropdown-item d-flex align-items-center" to="/user/profile">
                                    <i className="bx bx-user fs-5"></i><span>{t("navbar.profile")}</span>
                                </Link>
                            </li>
                            <li>
                                <div className="dropdown-divider mb-0"></div>
                            </li>
                            <Logout />
                        </ul>
                    </div>
                </nav>
            </div>
            <FullscreenModal text={t('logs.tableLogs')} logs={null} userId={null} />
        </header>
    )
}

export default Header