import { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../components/common/Button/button';
import InputDate from '../../components/common/Input/inputDate';
import InputFile from '../../components/common/Input/inputFile';
import InputMultyLang from '../../components/common/Input/inputMultyLang';
import InputNumber from '../../components/common/Input/inputNumber';
import InputSelect from '../../components/common/Input/inputSelect';
import InputPhone from '../../components/common/Input/inputPhone';
import Loading from '../../components/loading';
import ToastNotification from '../../components/toastNotfication';
import useImageUpload from '../../features/fileUplad/useImageUpload';
import { useServicePoints } from '../../features/servicePoint/useServicePoints';
import { useArea } from '../../features/area/useArea';
import { useSerivePointTypes } from '../../features/servicePointType/useServicePointType';
import { useHandleInputChange } from '../../hook/useHandleInputChange';
import { useInvalidLangs } from '../../hook/useInvalidLangs';
import useFetchData from './hook/useFetchData';
import { useOnUpdatePostClicked } from './hook/useOnUpdatePostClicked';
import { initialFormState } from './initialStates/update';
import InputText from '../../components/common/Input/inputText';
import ShowImage from '../../components/common/ShowImage/showImage';
const Update = () => {
    const [activeTab, setActiveTab] = useState('az');
    const languages = ['az', 'en', 'ru']
    const { t, i18n } = useTranslation();
    const lang = i18n.language
    const location = useLocation();
    const id = location.state || {};
    const { getServicePointById } = useServicePoints();
    const { allAreas } = useArea();
    const { allservicepointTypes } = useSerivePointTypes();
    const { uploadError, imageUrl, isLoading, handleFileChange, setImageUrl } = useImageUpload();
    const { formStateById, fetching, fetchError } = useFetchData(getServicePointById, id, initialFormState, lang);
    const { formState, handleInputChange } = useHandleInputChange(formStateById, fetching);
    const { response, loading, onUpdatePostClicked, formError } = useOnUpdatePostClicked(formState, imageUrl, id);
    const { invalidLangs } = useInvalidLangs(formError, loading, response);
    if (fetching) {
        return <Loading isLoading={fetching} />;
    }

    return (
        <HelmetProvider>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <h6 className="mb-0 text-uppercase table-name">{t("table.updateAdvertisement")}</h6>
                            <hr />
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <ul className="nav nav-tabs">
                                            {languages.map(lang => (
                                                <li className="nav-item" key={lang}>
                                                    <button
                                                        className={`nav-link ${invalidLangs?.includes(lang) ? 'invalid-bg' : ''}   ${activeTab === lang ? 'active' : ''}`}
                                                        onClick={() => setActiveTab(lang)}
                                                    >
                                                        {lang.toUpperCase()}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="p-4 border rounded">
                                        <form className="row g-3">
                                            <InputMultyLang
                                                label={`${t('properties.name')} (${activeTab})`}
                                                id={`name-${activeTab}`}
                                                name="name"
                                                value={formState.name?.[activeTab] || ''}
                                                error={formError?.[`name.${activeTab}`]}
                                                onChange={(e) => handleInputChange(e, activeTab)}
                                            />
                                            <InputMultyLang
                                                label={`${t('properties.address')} (${activeTab})`}
                                                id={`address-${activeTab}`}
                                                name="address"
                                                value={formState.address?.[activeTab] || ''}
                                                error={formError?.[`address.${activeTab}`]}
                                                onChange={(e) => handleInputChange(e, activeTab)}
                                            />

                                            <InputPhone
                                                name="phone"
                                                label={t('properties.phone')}
                                                formError={formError}
                                                value={formState?.phone}
                                                onChange={handleInputChange}
                                            />
                                            <InputNumber
                                                name="latitude"
                                                label={t('properties.latitude')}
                                                formError={formError}
                                                value={formState?.latitude}
                                                onChange={handleInputChange}
                                            />
                                            <InputText
                                                name="relevant_person"
                                                label={t('properties.relevant_person')}
                                                formError={formError}
                                                value={formState?.relevant_person}
                                                onChange={handleInputChange}
                                            />
                                            <InputNumber
                                                name="longitude"
                                                label={t('properties.longitude')}
                                                formError={formError}
                                                value={formState?.longitude}
                                                onChange={handleInputChange}
                                            />

                                            <InputDate
                                                label={t("properties.openingTime")}
                                                id="opening_time"
                                                name="opening_time"
                                                value={formState.opening_time}
                                                onChange={(e) => handleInputChange(e)}
                                                error={formError?.[`opening_time`]}
                                            />
                                            <InputDate
                                                label={t("properties.closingTime")}
                                                id="opening_time"
                                                name="closing_time"
                                                value={formState.closing_time}
                                                onChange={(e) => handleInputChange(e)}
                                                error={formError?.[`closing_time`]}
                                            />

                                            <InputSelect
                                                name="area_id"
                                                label={t("properties.area_id")}
                                                options={allAreas}
                                                value={formState.area_id}
                                                onChange={handleInputChange}
                                                formError={formError}
                                                lang={lang}
                                            />
                                            <InputSelect
                                                name="servicepoint_type_id"
                                                label={t("properties.servicePointType")}
                                                options={allservicepointTypes}
                                                value={formState.servicepoint_type_id}
                                                onChange={handleInputChange}
                                                formError={formError}
                                                lang={lang}
                                            />
                                            <InputFile
                                                label={t('media.file')}
                                                id="image"
                                                onChange={handleFileChange}
                                            />
                                            <ShowImage
                                                error={uploadError}
                                                imageUrl={imageUrl ?? formState.file_path}
                                                setImageUrl={setImageUrl}
                                                loading={loading}
                                                formState={formState}
                                            />
                                            <Button
                                                label={t('properties.save')}
                                                onClick={onUpdatePostClicked}
                                                isLoading={isLoading || loading}
                                                disabled={isLoading}
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastNotification response={response?.data || uploadError || fetchError} />
        </HelmetProvider>
    );
};

export default Update;