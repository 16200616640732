import { useState } from 'react';
import { useClient } from '../../../features/client/useClient';

export const useOnUpdatePostClicked = (formState = {}, id) => {

    const { updateClient } = useClient();
    const [response, setResponse] = useState();
    const [loading, setLoading] = useState(false);
    const [formError, setformError] = useState();
    const onUpdatePostClicked = async () => {
        setLoading(true);
        try {
            const formData = {
                nickname: formState.nickname,
                name: formState.name,
                email: formState.email,
                wallet: formState.wallet,
                phone: formState.phone,
                password: formState.password
            };
            const response = await updateClient(id, formData);
            setResponse(response);
            setformError(response.errors)
        } catch (error) {
            const errorResponse = error?.data?.errors || { message: 'Bilinmeyen hata oluştu' };
            setResponse(errorResponse);
            return errorResponse;
        } finally {
            setLoading(false);
        }
    };

    return {
        response,
        loading,
        formError,
        onUpdatePostClicked,
    };
};
