import React from 'react';
import './popup.css';
import { useTranslation } from 'react-i18next';
const Popup = ({ text, title, onClose, onConfirm, isOpen }) => {
    const { t } = useTranslation();
    if (!isOpen) return null;
    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content modal-content2" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                    <button type="button" className="close-button" onClick={onClose}><i class="lni lni-close"></i></button>
                </div>
                <div className="modal-body">
                    <p className='popup-text'>{text}</p>
                </div>
                <div className="modal-footer custom-flex">
                    <button type="button" className="btn btn-secondary custom-margin" onClick={onClose}>{t('common.close')}</button>
                    <button type="button" className="btn btn-primary" onClick={onConfirm}>{t('common.saveChanges')}</button>
                </div>
            </div>
        </div >
    );
};

export default Popup;
