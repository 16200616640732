import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/axiosConfig';
import { setLocalStorage, removeLocalStorage } from '../../utils/cookieUtils';

const initialState = {
    user: null,
    token: null,
    status: 'idle',
    error: null,
};

export const loginUser = createAsyncThunk('auth/loginUser', async (loginData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post('/login', loginData);  
        return response.data;
    } catch (error) {
        console.error('API Error:', error.response ? error.response.data : error.message);
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            state.token = null;
            removeLocalStorage('authToken'); 
            removeLocalStorage('authUser'); 
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
                state.token = action.payload.token;

                setLocalStorage('authToken', action.payload.token);
                setLocalStorage('authUser', action.payload.user);
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            });
    },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
