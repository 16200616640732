import Filter from "../filter";
import {initialState} from "../initialStates/create";
import {useServicePoints} from "../../../features/servicePoint/useServicePoints";
import FilterContainerComponent from "../../../components/rGlobalComponents/FilterContainerComponent";

const FilterContainer = () => {
    const { searchData } = useServicePoints();


    const handleFilterData = async (queryString) => {
        await searchData(queryString);
    }
    return (
        <FilterContainerComponent>
            <Filter model={initialState} handleFilterData={handleFilterData} />
        </FilterContainerComponent>
    )
}

export default FilterContainer