import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';
const fetcher = url => apiClient.get(url).then(res => res.data);
export const useAppSetting = () => {
    const { data, error } = useSWR(`/app-setting`, fetcher, { revalidateOnFocus: false });
    const setting = data?.data?.app_setting
    const update = async (id, updatedData) => {
        try {
            const response = await apiClient.put(`/app-setting/${id}`, updatedData);
            mutate(`/app-setting`);
            return response
        } catch (err) {
            return err.response.data
        }
    };

    const get = async () => {
        const response = await apiClient.get(`/app-setting`);
        return response.data;
    };

    return {
        setting,
        update,
        get
    }
}