import React from 'react';
import { useTranslation } from 'react-i18next';

const Index = ({ text }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="modal fade" id="exampleScrollableModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable custom-modal">
                    <div className="modal-content custom-modal">
                        <div className="modal-header">
                            <h5 className="modal-title custom-modal-title">{text}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="table custom-table">
                                <tbody>
                                    <tr>
                                        <th>{t('home.orderId')}</th>
                                        <td>10050751</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.orderNumber')}</th>
                                        <td>24090522113677971116</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.userId')}</th>
                                        <td>10023041</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.agentNumberBorrow')}</th>
                                        <td>100631</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.cabinetSerialBorrow')}</th>
                                        <td>10001183</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.servicePointBorrow')}</th>
                                        <td>10000798# Extra Night Club</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.leaseDuration')}</th>
                                        <td>2024-09-05 22:13:01</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.powerBankSerial')}</th>
                                        <td>93029162</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.agentNumberReturn')}</th>
                                        <td>100631</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.cabinetSerialReturn')}</th>
                                        <td>10001183</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.servicePointReturn')}</th>
                                        <td>10000798# Extra Night Club</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.returnTime')}</th>
                                        <td>2024-09-06 01:40:47</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.depositAmount')}</th>
                                        <td>3 AZE</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.freeTime')}</th>
                                        <td>10 dəqiqə</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.paymentTerms')}</th>
                                        <td>1.50 AZE/60 Dəqiqələr，Ən çoxu 24 saat ərzində 3 AZE</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.delayStatus')}</th>
                                        <td>Təxirə salınmayıb (-)</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.delayTime')}</th>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.discountDuration')}</th>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.finalAmount')}</th>
                                        <td>3.00 AZE</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.freezeDeposit')}</th>
                                        <td>3.00 AZE</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.actualPayment')}</th>
                                        <td>3.00 AZE</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.paymentChannel')}</th>
                                        <td>Epoint Azerbaijan (Epoint Azerbaijan)</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.paymentStatus')}</th>
                                        <td>Ödənilib</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.paymentTime')}</th>
                                        <td>2024-09-06 01:40:47</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.usageStatus')}</th>
                                        <td>Qaytarılmış</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.usageDuration')}</th>
                                        <td>3 Saat 27 dəqiqə 46 saniyə</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.pauseTime')}</th>
                                        <td>2024-09-06 22:23:01# Bu dəfə qaytarmaq, avtomatik alınmışdır</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.refundStatus')}</th>
                                        <td>Vəsaitin qaytarılması deyildir</td>
                                    </tr>
                                    <tr>
                                        <th>{t('home.refundTime')}</th>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('home.close')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
