import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const FilterButtonComponent = ({onClick}) => {
    const {t} = useTranslation();

    return (
        <button
            type="button"
            onClick={onClick}
            className="btn btn-light px-4 ml-2"
        >
            {t("filter.filter")}
        </button>
    );
};

FilterButtonComponent.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default FilterButtonComponent;
