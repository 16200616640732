import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHandleInputChange } from '../../hook/useHandleInputChange';
import { useOnSavePostClicked } from './hook/useOnSavePostClicked';
import { initialState } from './initialStates/create';
import useCabinetLoadScripts from '../../hook/scripts/useCabinetLoadScripts';
import InputText from '../../components/common/Input/inputText';
import Button from '../../components/common/Button/button';
import InputEmail from '../../components/common/Input/inputEmail';
import ToastNotfication from '../../components/toastNotfication';
import 'react-toastify/dist/ReactToastify.css';
const Index = () => {
    const { t } = useTranslation();
    const { formState, handleInputChange } = useHandleInputChange(initialState);
    const { response, loading, formPostError, onSavePostClicked } = useOnSavePostClicked(formState);
    useCabinetLoadScripts()
    return (
        <HelmetProvider>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="p-4 border rounded">
                                        <form className="row g-3" >

                                            <InputText
                                                name="name"
                                                label={t('properties.name')}
                                                formError={formPostError}
                                                value={formState?.name}
                                                onChange={handleInputChange}
                                            />
                                            <InputText
                                                name="surname"
                                                label={t('properties.surname')}
                                                formError={formPostError}
                                                value={formState?.surname}
                                                onChange={handleInputChange}
                                            />
                                            <InputEmail
                                                name="email"
                                                label={t('properties.email')}
                                                formError={formPostError}
                                                value={formState?.email}
                                                onChange={handleInputChange}
                                            />
                                            <InputText
                                                name="password"
                                                label={t('properties.password')}
                                                formError={formPostError}
                                                value={formState?.password}
                                                onChange={handleInputChange}
                                            />
                                            <Button
                                                label={t('properties.save')}
                                                onClick={onSavePostClicked}
                                                isLoading={loading}
                                                disabled={loading}
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastNotfication response={response} />
        </HelmetProvider>
    );
}

export default Index;
