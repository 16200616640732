import React from 'react';

const InputFile = ({ label, id, onChange }) => {
    return (
        <div className="col-md-6">
            <label htmlFor={id} className="form-label">
                {label}
            </label>
            <input
                type="file"
                className="form-control"
                id={id}
                onChange={onChange}
            />
        </div>
    );
};

export default InputFile;
