import React from "react";
import { Link } from "react-router-dom";
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import ActionButtons from "../../../components/common/Button/DeleteButton/actionButtons";
import OpenDetail from "../../../components/common/OpenDetail/openDetail";
import { useUser } from "../../../features/user/userActions";
import TableHeaderProperties from "./tableHeaderProperties";
import { useTranslation } from "react-i18next";

const Table = ({ data }) => {
    const { updateStatus, deleteUser, getUserObject } = useUser()
    const { t } =useTranslation()
    return (
        <div className="table-responsive">
            <table id="example2" className="table table-striped table-bordered">
                <thead>
                    <TableHeaderProperties />
                </thead>
                <tbody>
                    {data?.map((obj) => (
                        <tr key={obj.id}>
                            <td>{obj.id}</td>
                            <td>{obj.name}</td>
                            <td>{obj.surname}</td>
                            <td>{obj.email}</td>

                            <td>
                                <div className="col">
                                    <Link to={`/user/set-user-roles/${obj.id}`} state={{ obj }}>
                                        <div
                                            className="d-flex align-items-center justify-content-center theme-icons shadow-sm p-2 cursor-pointer rounded"
                                        >

                                            <div className="text-white">
                                                {t('role.editRole')}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </td>
                            <td className="toggle-td">
                                <ToggleSwitch initialState={obj.status} onChange={updateStatus} id={obj.id} />
                            </td>
                            <td >
                                <OpenDetail
                                    editLink={`/user/show-user/${obj.id}`}
                                    editState={getUserObject(obj)}
                                />
                            </td>
                            <td>
                                <ActionButtons
                                    editState={obj.id}
                                    deleteFunction={deleteUser}
                                />
                            </td>

                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <TableHeaderProperties />
                </tfoot>
            </table>
        </div>
    );
};

export default Table;
