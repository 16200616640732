import React, { useState } from "react";
import Modal from './modal';
import "./style.css";

const ShowImage = ({ imageUrl }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const isVideo = imageUrl?.split(".").pop() === "mp4";
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            {imageUrl != undefined && imageUrl != null && (
                <div className="col">
                    {isVideo ? (
                        <div className="app-setting-video">
                            <video
                                className="image-detail"
                                src={imageUrl}
                                controls
                                onClick={handleOpenModal}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    ) : (
                        <div className="app-setting-media">
                            <img
                                className="image-detail"
                                src={imageUrl}
                                alt="Thumbnail"
                                onClick={handleOpenModal}
                                style={{ cursor: "pointer" }}
                            />
                        </div >
                    )}

                    {isModalOpen && !isVideo && (
                        <Modal handleCloseModal={handleCloseModal} imageUrl={imageUrl} />
                    )}
                </div >
            )}

        </>
    );
};

export default ShowImage;
