import { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../components/common/Button/button';
import InputMultyLang from '../../components/common/Input/inputMultyLang';
import MultipleSelect from '../../components/common/MultipleSelect/MultipleSelect';
import ShowImage from '../../components/common/ShowImage/showImage';
import Loading from '../../components/loading';
import ToastNotification from '../../components/toastNotfication';
import { useAdvertisements } from '../../features/advertisements/useAdvertisements';
import useImageUpload from '../../features/fileUplad/useImageUpload';
import useVideoUpload from '../../features/fileUplad/useVideoUpload';
import { useServicePoints } from '../../features/servicePoint/useServicePoints';
import { useHandleInputChange } from '../../hook/useHandleInputChange';
import { useInvalidLangs } from '../../hook/useInvalidLangs';
import useFetchData from './hook/useFetchData';
import { useOnUpdatePostClicked } from './hook/useOnUpdatePostClicked';
import { initialFormState } from './initialStates/update';
const Update = () => {
    const [activeTab, setActiveTab] = useState('az');
    const languages = ['az', 'en', 'ru']
    const { t, i18n } = useTranslation();
    const lang = i18n.language
    const location = useLocation();
    const id = location.state || {};
    const { getAdvertisementById } = useAdvertisements();
    const { allServicePoints } = useServicePoints();
    const { uploadError, imageUrl, isLoading, setImageUrl, handleFileChange } = useImageUpload();
    const { formStateById, fetching, fetchError } = useFetchData(getAdvertisementById, id, initialFormState, lang);
    const { formState, handleInputChange } = useHandleInputChange(formStateById, fetching);
    const { uploadErrorvideo, videoUrl, isLoadingVideo, setVideoUrl, handleVideoChange } = useVideoUpload();
    const { response, formError, loading, onUpdatePostClicked } = useOnUpdatePostClicked(formState, imageUrl, videoUrl, id);
    const { invalidLangs } = useInvalidLangs(formError, loading)
    const handleServicePointChange = (selected) => {
        handleInputChange({ target: { name: 'servicepoint_ids', value: selected } });
    };

    const handleFileInputChange = (e) => {
        const fileExtension = e.target.value.split('.').pop();
        if (fileExtension === 'mp4') {
            setImageUrl(null)
            handleVideoChange(e)
        } else {
            setVideoUrl(null)
            handleFileChange(e)
        }
    };
    if (fetching) {
        return <Loading isLoading={fetching} />;
    }
    return (
        <HelmetProvider>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <h6 className="mb-0 text-uppercase table-name">{t("table.updateAdvertisement")}</h6>
                            <hr />
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <ul className="nav nav-tabs">
                                            {languages.map(lang => (
                                                <li className="nav-item" key={lang}>
                                                    <button
                                                        className={`nav-link ${invalidLangs.includes(lang) ? 'invalid-bg' : ''}   ${activeTab === lang ? 'active' : ''}`}
                                                        onClick={() => setActiveTab(lang)}
                                                    >
                                                        {lang.toUpperCase()}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className="p-4 border rounded">
                                        <form className="row g-3">

                                            <InputMultyLang
                                                label={`${t('properties.name')} (${activeTab})`}
                                                id={`name-${activeTab}`}
                                                name="name"
                                                value={formState.name?.[activeTab] || ''}
                                                error={formError?.[`name.${activeTab}`]}
                                                onChange={(e) => handleInputChange(e, activeTab)}
                                            />

                                            <div className="col-md-6">
                                                <label htmlFor="file_path" className="form-label">{t('media.file')}</label>
                                                <input
                                                    name='file_path'
                                                    onChange={(e) => { handleFileInputChange(e) }}
                                                    type="file"
                                                    className={`form-control`}
                                                    id="file_path"
                                                />
                                                <ShowImage
                                                    error={uploadError}
                                                    imageUrl={imageUrl ?? formState.file_path}
                                                    setImageUrl={setImageUrl}
                                                    loading={loading}
                                                    formState={formState}
                                                />
                                            </div>



                                            <MultipleSelect
                                                options={allServicePoints}
                                                onSelectionChange={handleServicePointChange}
                                                selectedValues={formState.servicepoints ?? formStateById?.servicepoints}
                                                label={t('servicePoint.servicePoints')}
                                                name="servicepoint_ids"
                                                all={true}
                                            />

                                            {formError?.[`servicepoint_ids`] && (<div className="invalid-feedback">{formError[`servicepoint_ids`].join(', ')}</div>)}
                                            <Button
                                                label={t('properties.save')}
                                                onClick={onUpdatePostClicked}
                                                isLoading={loading || isLoading || isLoadingVideo}
                                                disabled={loading || isLoading || isLoadingVideo}
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastNotification response={response || uploadError || fetchError || uploadErrorvideo} loading={loading} />
        </HelmetProvider>
    );
};

export default Update;