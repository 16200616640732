import React from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

const DataTablePagination = ({
    currentPage,
    itemsPerPage,
    total,
    lastPage,
    handlePageClick,
    setPerPge,
}) => {
    const { t } = useTranslation()
    return (
        <div className="row">
            <div className="col-sm-12 col-md-12">
                <div
                    className="dataTables_info"
                    id="example_info"
                    role="status"
                    aria-live="polite"
                >
                    {t("pagination.info", {
                        currentPage,
                        lastPage,
                        itemsPerPage,
                        total
                    })}
                </div>
            </div>
            <div className="col-sm-12 col-md-12 perPage-container">
                <div className="form-group ">
                    <select className="form-select perPage"
                        id="recordsPerPage"
                        onChange={(e) => setPerPge(e.target.value)}
                        defaultValue={itemsPerPage}
                    >
                        <option value="10">10</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example_paginate"
                >
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next"
                        onPageChange={(e) => { handlePageClick(e.selected + 1) }}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={lastPage}
                        forcePage={currentPage - 1}
                        previousLabel="Previous"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination justify-content-end"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </div>
            </div>
        </div>
    );
};

export default DataTablePagination;
