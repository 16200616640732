import React, { useState, useEffect } from 'react';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const ToastNotification = ({ response }) => {
  const [lastTimestamp, setLastTimestamp] = useState(null);

  const currentTimestamp = new Date().getTime();
  useEffect(() => {
    if (response) {

      if (currentTimestamp !== lastTimestamp) {

        if (response.code === 200 || response.code === 201) {
          NotificationManager.success(response.message || 'Əməliyyat uğurla yerinə yetirildi!', '', 3000);
        } else if (response.message) {
          NotificationManager.error(response.message, '', 3000);
        }
      }
    }
  }, [response, lastTimestamp]);

  return <NotificationContainer />;
};

export default ToastNotification;
