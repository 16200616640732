import React, { useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useTranslation } from 'react-i18next';

const Index = ({ getFunction, headers }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const handleExportPDF = async (e) => {
        e.preventDefault();
        const doc = new jsPDF();
        const data = await getFunction()
        if (data.length === 0) {
            alert("No data available for export");
            return;
        }

        const columns = headers
            ? headers.map(header => ({
                title: header.replace(/_/g, ' '),
                dataKey: header.toLowerCase().replace(/\s+/g, '_')
            }))
            : Object.keys(data[0]).map(key => ({
                title: t(key.replace(/_/g, ' ')),
                dataKey: key
            }));

        const rows = data.map(item =>
            columns.reduce((acc, col) => {
                const fieldValue = item[col.dataKey];
                if (fieldValue && typeof fieldValue === 'object') {
                    acc[col.dataKey] = fieldValue[lang] || '';
                } else {
                    acc[col.dataKey] = fieldValue || '';
                }
                return acc;
            }, {})
        );

        doc.autoTable({
            head: [columns.map(col => col.title)],
            body: rows.map(row => columns.map(col => row[col.dataKey])),
            startY: 20
        });

        doc.save('document.pdf');
    };

    return (
        <button
            className="btn btn-light export-buttons"
            type="button"
            onClick={handleExportPDF}
        >
            <span>PDF</span>
        </button>
    );
}

export default Index;
