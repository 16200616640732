import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const ResetButtonComponent = ({onClick}) => {
    const {t} = useTranslation();

    return (<button
        type="button"
        onClick={onClick}
        className="btn btn-primary px-4 ml-2"
    >
        {t("filter.reset")}
    </button>);
};

ResetButtonComponent.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default ResetButtonComponent;
