import axios from 'axios';
import { BASE_URL, X_APIKEY } from '../config';

const apiClient = axios.create({
    baseURL: BASE_URL,
});

apiClient.interceptors.request.use(
    (config) => {
        const token = JSON.parse(localStorage.getItem('authToken'));
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['x-api-key'] = 'bfcfc35c42b8054ec22c7366799f0ce4';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default apiClient;
