import React from "react";
import { useTranslation } from 'react-i18next';
import "react-toastify/dist/ReactToastify.css";
import DataTablePagination from "../../components/common/DataTablePagination/dataTablePagination";
import TableHeader from "../../components/common/TableHeader/tableHeader";
import ExportButtons from "../../components/ExportButtons";
import Loading from "../../components/loading";
import MoreInfo from '../../components/moreInfo/moreInfo';
import { useServicePoints } from '../../features/servicePoint/useServicePoints';
import FilterContainer from "./components/filterContainer";
import Table from './components/table';
import "./style.css";
const List = () => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language
    const { getAllServicePoints, servicePoints, isLoading, isError, itemsPerPage, total, currentPage, lastPage, setPage, setPerPge } = useServicePoints();
    const breadcrumbLinks = [
        { url: '/', icon: 'bx bx-home-alt', label: t('common.home') },
        { label: t('servicePoint.servicePoints') }
    ];
    const infoData = [
        { text: t('more_info.service_point_amount'), data: '100' },
        { text: t('more_info.service_points_with_cabinet'), data: '50' },
        { text: t('more_info.service_points_without_cabinet'), data: '30' }
    ];

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }
    if (isError) {
        return <Loading isLoading={false} isError={isError} />;
    }
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <TableHeader
                        breadcrumbLinks={breadcrumbLinks}
                        addLink="/service-point/create"
                        addButtonText={t('servicePoint.addServicePoint')}
                    />
                    <div className="card">
                        <MoreInfo infoData={infoData} />
                        <FilterContainer />
                        <div className="card-body">
                            <ExportButtons
                                getFunction={getAllServicePoints}
                                headers={["name", "address", "latitude", "longitude", "phone"]}
                                excelHeaders={["name", "address", "latitude", "longitude", "phone", "status", "relevant_person", "servicepoint_number", "opening_time", "closing_time"]}
                            />
                            <Table
                                data={servicePoints}
                                lang={lang}
                            />
                            <DataTablePagination
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                total={total}
                                lastPage={lastPage}
                                handlePageClick={setPage}
                                setPerPge={setPerPge}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default List;
