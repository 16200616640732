export const initialFormState = {
    id: '',
    relevant_person: '',
    servicepoint_type_id: null,
    name: {
        az: '',
        en: '',
        ru: ''
    },
    address: {
        az: '',
        en: '',
        ru: ''
    },
    image: '',
    area_id: '',
    opening_time: '',
    closing_time: '',
    phone: '',
    latitude: null,
    longitude: null
};
