import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';


const fetcher = url => apiClient.get(url).then(res => res.data);
export const useRoles = (roleId) => {
    const { data: roles, error: rolesError } = useSWR('/roles', fetcher, { revalidateOnFocus: false });
    // const { data: permissions, error: permissionsError } = useSWR('/permissions', fetcher, { revalidateOnFocus: false });
    const { data: role, error: roleError } = useSWR(`/role/${roleId}`, fetcher, { revalidateOnFocus: false });

    const setRolePermission = async (id, updatedData) => {
        // try {
        //     const response = await apiClient.put(`/role/${id}/permissions`, updatedData);
        //     mutate('/servicepoint');
        // } catch (error) {
        //     console.error("Failed to update role permissions:", error);
        // }
    };

    return {
        roles,
        // permissions,
        role,
        isLoading: !rolesError && !roles,
        isError: rolesError,
        setRolePermission,
        mutate
    };
};