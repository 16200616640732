import React from "react";
import { useTranslation } from "react-i18next";

const DynamicTable = ({ data }) => {
    const { t } = useTranslation();

    const formatValue = (value) => {
        if (typeof value === "object" && value !== null) {
            return null;
        }
        return value !== null && value !== undefined ? value : "-";
    };

    const generateRows = (data) => {
        if (!data) return null;

        return Object.entries(data).map(([key, value], index) => {
            const formattedValue = formatValue(value);

            if (formattedValue === null) {
                return (
                    <React.Fragment key={index}>
                        <tr>
                            <th colSpan="2" className="th-header">{t(`properties.${key}`)}: {index+1}</th>
                        </tr>
                        {generateRows(value)}
                    </React.Fragment>
                );
            }
            return (
                <tr key={index}>
                    <th>{t(`properties.${key}`)}</th>
                    <td>{formattedValue}</td>
                </tr>
            );
        });
    };

    return (
        <table className="table custom-table">
            <tbody>{generateRows(data)}</tbody>
        </table>
    );
};

export default DynamicTable;
