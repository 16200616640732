import { useState } from 'react';
import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';

const fetcher = url => apiClient.get(url).then(res => res.data);

export const useAdvertisements = () => {
    const [itemsPerPage, setPerPge] = useState(50)
    const [page, setPage] = useState(1);
    const { data, error } = useSWR(`/advertisement?perPage=${itemsPerPage}&page=${page}`, fetcher, {
        revalidateOnFocus: false
    });

    const lastPage = data?.data?.advertisements?.last_page;
    const total = data?.data?.advertisements?.total;
    const advertisements = data?.data?.advertisements?.data;

    const createAdvertisement = async (newData) => {
        try {
            const response = await apiClient.post('/advertisement', newData, false);
            mutate('/advertisement?perPage=${itemsPerPage}&page=${page}');
            mutate('/advertisement');
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    };

    const updateAdvertisement = async (id, formData) => {
        try {
            const response = await apiClient.put(`/advertisement/${id}`, formData);
            mutate(`/advertisement?perPage=${itemsPerPage}&page=${page}`);
            mutate(`/advertisement/${id}`)
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    };

    const deleteAdvertisement = async (id) => {
        try {
            const response = await apiClient.delete(`/advertisement/${id}`);
            mutate(`/advertisement?perPage=${itemsPerPage}&page=${page}`);
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    };

    const searchAdvertisement = async (queryString) => {
        try {
            const url = queryString ? `/advertisement?perPage=${itemsPerPage}&${queryString}` : `/advertisement?perPage=${itemsPerPage}&page=${page}`;
            const response = await apiClient.get(url);
            mutate(
                `/advertisement?perPage=${itemsPerPage}&page=${page}`,
                {
                    data: {
                        advertisements: {
                            data: response?.data?.data?.advertisements?.data || [],
                            current_page: 1,
                            last_page: response?.data?.data?.advertisements?.last_page,
                            total: response?.data?.data?.advertisements?.total,
                            per_page: itemsPerPage
                        }
                    },
                    message: 'Success',
                    code: 200
                },
                false
            );

            return response?.data?.data?.advertisements?.data;
        } catch (err) {
            return err.response?.data;
        }
    };

    const getAdvertisementById = async (advertisementId) => {
        const response = await apiClient.get(`/advertisement/${advertisementId}`);
        return response.data;
    };

    const updateStatus = async (id) => {
        try {
            const response = await apiClient.put(`/advertisement/${id}/status`);
            mutate(`/advertisement?perPage=${itemsPerPage}&page=${page}`);
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    };
    const getAll = async () => {
        try {
            const response = await apiClient.get(`/advertisement`);
            mutate(`/advertisement`);
            return Array.isArray(response?.data?.data?.advertisements) ? response?.data?.data?.advertisements : [];
        } catch (err) {
            return err.response.data;
        }
    }
    return {
        advertisements,
        isLoading: !error && !data,
        isError: error,
        itemsPerPage,
        lastPage,
        currentPage: page,
        total,
        updateStatus,
        setPage,
        createAdvertisement,
        updateAdvertisement,
        deleteAdvertisement,
        searchAdvertisement,
        getAdvertisementById,
        mutate,
        setPerPge,
        getAll
    };
};