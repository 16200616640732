import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const flags = {
    en: '/assets/images/county/en.png',
    az: '/assets/images/county/az.svg',
    ru: '/assets/images/county/ru.svg'
};

const Language = () => {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(i18n.language);

    const handleLanguageChange = (selectedLang) => {
        i18n.changeLanguage(selectedLang);
        localStorage.setItem('i18nextLng', selectedLang);
        setLang(selectedLang);
    };

    useEffect(() => {
        const storedLanguage = localStorage.getItem('i18nextLng');
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
            setLang(storedLanguage); 
        }
    }, [i18n]);

    return (
        <li className="nav-item dropdown dropdown-language d-sm-flex">
            <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown">
                <img src={flags[lang] || flags['en']} width="22" alt="" />
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
                <li>
                    <button className="dropdown-item d-flex align-items-center py-2" onClick={() => handleLanguageChange('en')}>
                        <img src={flags['en']} width="20" alt="" /><span className="ms-2">English</span>
                    </button>
                </li>
                <li>
                    <button className="dropdown-item d-flex align-items-center py-2" onClick={() => handleLanguageChange('az')}>
                        <img src={flags['az']} width="20" alt="" /><span className="ms-2">Azerbaijan</span>
                    </button>
                </li>
                <li>
                    <button className="dropdown-item d-flex align-items-center py-2" onClick={() => handleLanguageChange('ru')}>
                        <img src={flags['ru']} width="20" alt="" /><span className="ms-2">Russian</span>
                    </button>
                </li>
            </ul>
        </li>
    );
}

export default Language;
