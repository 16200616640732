import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const useSessionChecker = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = () => {
      const sessionData = localStorage.getItem('rememberMeSession');

      if (sessionData) {
        const parsedSession = JSON.parse(sessionData);
        const currentTime = Date.now();

        if (currentTime > parsedSession.expiresAt) {
          localStorage.removeItem('authToken');
          localStorage.removeItem('authUser');
          localStorage.removeItem('rememberMeSession');
          toast.warning('Sessiya müddəti bitib. Login səhifəsinə yönləndirilirsiniz...');
          navigate('/login');
        }
      }
    };

    checkSession();
  }, [navigate]);
};

export default useSessionChecker;
