import React from 'react';

const InputText = ({ name, label, formError, value, onChange, className = "col-md-6", editable = false }) => {
    return (
        <div className={className}>
            <label className="form-label">{label}</label>
            <input
                type="text"
                className={`form-control ${formError && formError[name] && formError[name].length > 0 ? 'is-invalid' : ''}`}
                name={name}
                value={value || ''}
                onChange={onChange}
                disabled={editable}
            />
            {formError?.[name] && (
                <div className="invalid-feedback">
                    {formError[name].join(', ')}
                </div>
            )}
        </div>
    );
};

export default InputText;
