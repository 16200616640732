import { useTranslation } from 'react-i18next';
const Index = ({ color, amount = 10.000, text }) => {
    const { t } = useTranslation();

    return (
        <>
            <div class="col">
                <div class={`card radius-10 ${color} bg-gradient`}>
                    <div class="card-body custom-total-amaunt">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-white">{text} </p>
                                <h4 class="my-1 text-white">{amount}</h4>
                            </div>
                            <div class="text-white ms-auto font-35"><i class='bx bx-dollar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index